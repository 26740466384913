<div class="pre-loader" *ngIf="preloader==true">
  <div class="load-icon">
    <div class="ring-load">
      <div class="circle"></div>
      <!--<img src="assets/images/Favicon.svg" />-->
      <div class="pageload-icon"></div>
    </div>
  </div>
</div>
<div id="fnol_videos">
  <div class="content-wrapper  no-p" style="padding-bottom:60px;">
    <div class="row no-m overflow_hide mobile_view">
      <div class="lb-head-single vertical-center bg-white">
        <div class="col-lg-1 col-md-3 col-sm-2 col-3 p-0">
          <div class="">
            <h5 class="Heading_h5">Videos</h5>
            <span class="pull-right">
              <i class="far fa-filter" data-toggle="modal" data-target="#filters" *ngIf="!ActivatedFilter" tooltip="Filters" placement="left" [hide-delay]="0"></i>
              <i class="fas fa-filter" data-toggle="modal" data-target="#filters" *ngIf="ActivatedFilter" tooltip="Filters" placement="left" [hide-delay]="0"></i>
            </span>
          </div>


        </div>

        <div class="col-md-2 col-sm-2 col-4">
          <input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchVideos">
        </div>
        <div class="col-md-3 col-sm-3 col-5">
          <select class=" form-control event_date" [(ngModel)]="dateStatus" (change)="changeStatus()">
            <option *ngFor="let item of eventStatusList; index as i" value="{{item?.id}}">{{item?.name}}</option>
          </select>
        </div>
        <div class="col-md-3 col-sm-2 col-5 mobile_view_date" *ngIf="customdate==true">
          <input type="text" style="font-size:11px!important"
                 placeholder="Date"
                 class="form-control"
                 [(ngModel)]="videoCustomDate"
                 [maxDate]="maxDate"
                 bsDaterangepicker
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', isAnimated: true, showWeekNumbers:false}"
                 (bsValueChange)="customVideoFun($event)">
        </div>

        <div class="col-md-5 col-sm-5 col-4 mobile_view_allvech">
          <div class="all_veh_selection">
            <div class="d-flex_align_c">
              <div class="form-group all_veh_checkbox">
                <div class="checkspan">
                  <span class="checkbox">
                    <label class="d-flex">
                      <input type="checkbox" class="form-control checkbox_coustom" [(ngModel)]="AllVehicles" (change)="AllVehicleschange(AllVehicles)">
                      <span class="checkbox-material"><span class="check"></span></span>All Vehicles
                    </label>
                  </span>
                </div>
              </div>
              <div class="form-group all_veh_dropdown" *ngIf="!AllVehicles">
                <!--<select class="form-control" [(ngModel)]="selectedVehicle" (change)="MultiVehicles(selectedVehicle)">
                <option [ngValue]="null" selected>Select Vehicle</option>
                <option *ngFor="let item of vehiclesByFleet" [ngValue]="item">{{item.VRN}}</option>
              </select>-->


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="all-videos  row mt-1 no-m">
      <!--<div class="dropdown" *ngIf="selectedVehicleList.length!=0" style="position: absolute; right: 36%; top: 32px;">
      <div class="">
        <div class="panel-group mt-1 card-scroll veh_name" style="height: auto;">
          <div class="panel panel-default all_vehicles_panel">
            <div class="panel-body append_value select_vech_video " id="items">
              <p>Selected vehicles </p>
              <p *ngFor="let item of selectedVehicleList">{{item.VRN}}<i class="fas fa-times pull-right" (click)="removeSelectedVrn(item.DeviceIdentifier)"></i></p>
            </div>
          </div>
        </div>
      </div>
    </div>-->
      <div class="card-scroll " [ngClass]="{'col-12 col-lg-12 col-md-12 col-sm-12':colp,'col-6 col-lg-3 col-md-6 col-sm-6 tab_mb_hide_video':!colp}">
        <div class="new_ddl_list_man" [ngClass]="{'d-none': AllVehicles}">
          <ng-multiselect-dropdown [placeholder]="'Select Vehicle'"
                                   [data]="vehiclesByFleet"
                                   [(ngModel)]="selectedVehicle"
                                   [settings]="dropdownSettings1"
                                   (onSelect)="onItemSelect($event)"
                                   (onDeSelect)="onItemDeSelect($event)">
          </ng-multiselect-dropdown>
        </div>
        <div class="row">
          <div style="margin: auto;" class="tab-alert no-videos" *ngIf="(videosDetails |searchFilter:{
      RegistrationNumber:searchVideos, Drivername:searchVideos, Address: searchVideos, EventDate: searchVideos, ClassificationName: searchVideos, displayname: searchVideos})?.length==0">
            <h5> <span style="color:red">  Videos Not</span> Found</h5>
          </div>
          <div class="mb-1" *ngFor="let video of videosDetails |
          paginate:{
          itemsPerPage:itemsPerPage,
          currentPage:page,
          totalItems:totalLength }| searchFilter: { RegistrationNumber:searchVideos, Drivername:searchVideos, Address: searchVideos, EventDate: searchVideos, ClassificationName: searchVideos, displayname: searchVideos};" [ngClass]="{'col-12 p-1':!colp,'mb-1 col-lg-3 col-md-12 col-sm-12 col-12':colp}">
            <div *ngIf="video?.StatusId==3  " [ngClass]="{ 'disabled': constants.isDivEnabled }">
              <div (click)="getVideos(video)" data-toggle="modal" data-target="#one_ch">

                <video class="video-ch" style="background: #323232; ">
                  <source [src]="video?.Videos[0]?.BlobUrl || getFirstNonNullBlobUrl(video?.Videos)" type="video/mp4">
                </video>

                <label class="pl-2 plus_one" [ngClass]="{'btn-success':video?.SuccessCount===video?.Videos?.length,'btn-warning':video?.SuccessCount!=video?.Videos?.length, 'btn-danger':video?.Videos?.length==0}">{{video.SuccessCount}}/{{video?.Videos?.length}}</label>
              </div>
            </div>
            <!--<div *ngIf="video?.StatusId==3">
            <div [class.disabled-div]="isMethodClicked" (click)="isMethodClicked ? null : getVideos(video)" data-toggle="modal" data-target="#one_ch">
              <video class="video-ch" style="background: #323232;">
                <source [src]="video?.Videos[0]?.BlobUrl || getFirstNonNullBlobUrl(video?.Videos)" type="video/mp4">
              </video>
              <label class="pl-2 plus_one" [ngClass]="{'btn-success':video?.SuccessCount===video?.Videos?.length,'btn-warning':video?.SuccessCount!=video?.Videos?.length, 'btn-danger':video?.Videos?.length==0}">{{video.SuccessCount}}/{{video?.Videos?.length}}</label>
            </div>
          </div>-->

            <div class="position_rel" *ngIf="video?.StatusId===1 || video?.StatusId===2">
              <label class="pl-2 channel_name">{{video.ResponseReceivedFromDevice}}</label>
              <video class="video-ch bg_black_vd">
                <source [src]="" type="video/mp4">
              </video>
            </div>
            <div class="position_rel" *ngIf="video?.StatusId===5 || video?.StatusId===4">
              <label class="pl-2 channel_name">{{video?.ResponseReceivedFromDevice}}</label>
              <video class="video-ch bg_black_vd">
                <source [src]="" type="video/mp4">
              </video>
            </div>
            <div class="position_rel" *ngIf="video?.StatusId===7">
              <label class="pl-2 channel_name">{{video?.ResponseReceivedFromDevice}}</label>
              <video class="video-ch bg_black_vd">
                <source [src]="" type="video/mp4">
              </video>
            </div>
            <div class="video_details">

              <div class="row no-m">
                <div class="col-md-5 left_5_coloum">
                  <div class="">
                    <i class="far fa-car mr-05"></i>
                    <span class="video_box_drive_name pl-0">{{video?.RegistrationNumber}}</span>
                  </div>
                  <div class="dflex_align_center">
                    <i class="fas fa-user mr-05"></i>
                    <span class="video_box_drive_name videos_txt_ellipse  pl-0" *ngIf="video?.Drivername==null">N/A</span>
                    <span class="video_box_drive_name videos_txt_ellipse  pl-0" *ngIf="video?.Drivername"> {{video?.Drivername}}</span>
                  </div>
                  <div class="dflex_align_center">
                    <i class="far fa-comment-lines mr-05"></i>
                    <span class="video_box_drive_name pl-0 small_txt txt_overflow">Requested by {{video?.UserName}}</span>
                  </div>
                </div>
                <div class="col-md-7 right_7_coloum video_details_bod_left">
                  <div class="dflex_align_center">
                    <div class="event_img_video sevirity_boder" tooltip="{{video?.displayname}}">
                      <img src="\assets\images\eventsBig\icon_event{{video?.EventTypeId}}{{video?.Severity}}.svg">
                    </div>
                    <div class="video_box_drive_name d-inline-block ">
                      <p class="d-inline-block mb-0 font_12" [ngClass]="{'highSeverityText':video.Severity==3,'mediumSeverityText':video.Severity==2,'lowSeverityText':video.Severity==1}">{{video?.displayname}}</p>
                    </div>


                  </div>

                  <div class="">
                    <i class="far fa-calendar-edit mr-05"></i>
                    <span class="video_box_drive_name pl-0">{{video?.EventDate}}</span>
                  </div>


                  <div class="dflex_align_center">
                    <i class="far fa-file-chart-pie mr-05"></i>
                    <div class="video_box_drive_name d-inline-block ">
                      <p class="d-inline-block">{{video?.ClassificationName}}</p>
                      <p class="d-inline-block" *ngIf="video?.ClassificationName==null">N/A</p>

                    </div>
                  </div>
                </div>
                <div>


                </div>
              </div>
              <div class="row no-m left_5_coloum no-m pt-0">
                <div class="col-md-10 no-p">
                  <div class="dflex_align_center">
                    <i class="far fa-map-marker-alt mr-05"></i>
                    <span class="video_box_drive_name pl-0 small_txt txt_overflow">{{video?.Address}}</span>
                  </div>
                </div>
                <div class="col-md-2">

                  <a class="btn btn-success float-right video_more_btn" href="home/videos#eventMap" (click)="viewEventMap(video)"><i class="fas fa-chevron-double-right"></i></a>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-9 col-md-12 col-sm-12 col-12 pl-0 pr-0 videos_main" *ngIf="!colp">
        <div class="video_module_events">
          <app-view-map [viewMap]="videoEventId" (calpVal)="viewValueMap($event)" [eventDateValue]="dateData" (getVideoData)="callVideoEvent($event)">
          </app-view-map>
        </div>
        <div class="livefoot row no-gutters videos_pagenation_left" *ngIf="(videosDetails |searchFilter:{
RegistrationNumber:searchVideos, Drivername:searchVideos, Address: searchVideos, EventDate: searchVideos, ClassificationName: searchVideos, displayname: searchVideos})?.length!=0">
          <pagination-controls class="" (pageChange)="pageChanged(page = $event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
    <div class="modal fade" id="one_ch" role="dialog" *ngIf="videoModal">

      <div class="modal-dialog video_modal_popup" #fullScreen [ngClass]="{'video_max_popup': maxClass,'video_modal_popup' :!maxClass}">

        <div class="modal-content max" [ngClass]="{'video_max_popup': !maxClass,'video_modal_popup' :maxClass}">
          <div class="modal-header mobile_controls_header">
            <div class="row" style="width: 93%;">
              <div class="col">
                <span class="video_box_drive_name pl-0" *ngIf="VideoInfo?.Drivername"> <i class="fas fa-user"></i> {{VideoInfo?.Drivername}} </span>
                <span class="video_box_drive_name pl-0" *ngIf="VideoInfo?.Drivername==null"> <i class="fas fa-user"></i> N/A </span>
              </div>
              <div class="col">
                <span class="video_box_drive_name pl-0">  <i class="far fa-car"></i> {{VideoInfo?.RegistrationNumber}}</span>
              </div>
              <div class="col">
                <span class="video_box_drive_name pl-0"><i class="far fa-calendar-edit"></i> {{VideoInfo?.EventDate | date: 'short'}}</span>
              </div>
              <div class="col">
                <span class="sevirity_med">
                  <!-- <img src="\assets\images\eventpng\icon_event22.png" style="width: 20px;"> Harsh Acceleration-->
                </span>
                <p class="pl-05 d-inline-block" [ngClass]="{'highSeverityText':VideoInfo.Severity==3,'mediumSeverityText':VideoInfo.Severity==2,'lowSeverityText':VideoInfo.Severity==1}"> {{VideoInfo.displayname}}</p>
              </div>
            </div>
            <span class="pull-right mr-1 ml-20 mob_dice" *ngIf=" DeviceChannelCount==1 && videoData?.length==1">
              <a (click)="stretchVids()" *ngIf="displayStretch"><i class="fas fa-expand cursor-pointer"></i></a>
            </span>
            <span class="pull-right mr-1 ml-20 mob_dice" *ngIf=" DeviceChannelCount>1 && videoData?.length>1">
              <a class="mr-05" (click)="VideoChannelView(1)">  <i class="fa fa-square fleetly_color"></i></a>
              <a class="mr-05" (click)="VideoChannelView(2)">  <i class="fa fa-th-large fleetly_color"></i></a>
              <a (click)="stretchVids()" *ngIf="displayStretch"><i class="fas fa-expand fleetly_color"></i></a>
            </span>
            <button type="button" class="close" (click)="minimize()" data-dismiss="modal">&times;</button>
            <h4 class="modal-title"></h4>
          </div>
          <div class="modal-body card-scroll p-05" style=" height: calc(100vh - 62px) !important;">
            <div *ngIf="DeviceChannelCount==1">
              <div class="video videosmallview" *ngFor="let item of videoData; index as i">
                <div *ngIf="item.StatusCode == 200">
                  <video id="selectedVideo{{videoData[0]?.ChannelName}}" [muted]="true" (ended)="vidEnded()" [src]="item.BlobUrl" (drop)="drop('drop1')" class="media-responsive "
                         [ngClass]="{'videoLargeView': !stretch, 'video_player': stretch}">
                    Browser not supported
                  </video>
                </div>
                <div class="position_rel" *ngIf="item.StatusCode !== 200">
                  <!--<p>Video still in processing...!</p>-->
                  <label class="pl-2 channel_name">{{videoData[0]?.StatusMessage}}</label>
                  <video class="video-ch bg_black_vd">
                    <source [src]="" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
            <div *ngIf="DeviceChannelCount>1">
              <div class=" row no-m">
                <!--<div class="col-md-12 no-p">-->
                <div class="p-05 card-scroll" [ngClass]="{'col-md-10 no-p':playVideo,'col-md-12 no-p':!playVideo}">
                  <div class="row no-m">

                    <div class="video text-center" *ngIf="(channelcountchange==1)" [ngClass]="{'col-md-12':channelcountchange==1}">
                      <div *ngIf="selectedSingleVideo.StatusCode == 200" class="text-center">
                        <label [id]="selectedSingleVideo.Id" class="channel_txt">{{selectedSingleVideo?.ChannelName}}</label>
                        <video [muted]="true" id="selectedVideo{{selectedSingleVideo.ChannelName}}" [src]="selectedSingleVideo?.BlobUrl" (dblclick)="fullScreen('video1')" (ended)="vidEnded()" (drop)="allowDrop('drop1',selectedSingleVideo.ChannelName,selectedSingleVideo.Id)" (dragover)="false" class="media-responsive "
                               [ngClass]="{'videoLargeView': !stretch, 'video_player': stretch}">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="selectedSingleVideo.StatusCode !== 200" class="bg_black_vd modal_video_bigbox" style="width: auto%; height: 34vh;">
                        <p class="channel_txt bg_black">{{selectedSingleVideo?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{selectedSingleVideo?.StatusMessage}}</p>
                        </div>
                        <video [src]="selectedSingleVideo?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>


                    <div class="video text-center" *ngIf="(channelcountchange==2)" [ngClass]="{'col-md-6':(channelcountchange==2) && videoData?.length<=4,'col-md-12':channelcountchange==1,'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[0].StatusCode == 200 && videoData[0]" class="text-center">
                        <label [id]="videoData[0].Id" class="channel_txt">{{videoData[0]?.ChannelName}}</label>
                        <video [muted]="true" id="selectedVideo{{videoData[0]?.ChannelName}}" [src]="videoData[0]?.BlobUrl" (dblclick)="fullScreen('video1')" (ended)="vidEnded()" (drop)="allowDrop('drop1',videoData[0].ChannelName,videoData[0].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[0].StatusCode !== 200 && videoData[0]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[0]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[0]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[0]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video   text-center" *ngIf="(channelcountchange==2) && videoData?.length>=2" [ngClass]="{'col-md-4':(channelcountchange==2) && videoData?.length>=5,'col-md-6':(channelcountchange==2) && videoData?.length<=4}">
                      <div *ngIf="videoData[1].StatusCode == 200 && videoData[1]">
                        <label [id]="videoData[1].Id" class="channel_txt">{{videoData[1]?.ChannelName}}</label>
                        <video [muted]="true" id="selectedVideo{{videoData[1]?.ChannelName}}" [src]="videoData[1]?.BlobUrl" (dblclick)="fullScreen('video2')" (drop)="allowDrop('drop2',videoData[1].ChannelName,videoData[1].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[1]?.StatusCode !== 200 && videoData[1]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[1]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[1]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[1]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video   text-center" *ngIf="(channelcountchange==2) && videoData?.length>=3" [ngClass]="{'col-md-4':(channelcountchange==2) && videoData?.length>=5,'col-md-6':(channelcountchange==2) && videoData?.length<=4}">
                      <div *ngIf="videoData[2].StatusCode == 200 && videoData[2]">
                        <label [id]="videoData[2].Id" class="channel_txt">{{videoData[2]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[2]?.BlobUrl" (dblclick)="fullScreen('video3')" id="selectedVideo{{videoData[2]?.ChannelName}}" (drop)="allowDrop('drop3',videoData[2].ChannelName,videoData[2].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[2].StatusCode !== 200 && videoData[2]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[2]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[2]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[2]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video text-center" *ngIf="(channelcountchange==2) && videoData?.length>=4" [ngClass]="{'col-md-4':(channelcountchange==2)&& videoData?.length>=5,'col-md-6':(channelcountchange==2) && videoData?.length==4}">
                      <div *ngIf="videoData[3].StatusCode == 200 && videoData[3]">
                        <label [id]="videoData[3].Id" class="channel_txt">{{videoData[3]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[3]?.BlobUrl" id="selectedVideo{{videoData[3]?.ChannelName}}" (dblclick)="fullScreen('video4')" (drop)="allowDrop('drop4',videoData[3]?.ChannelName,videoData[3].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[3].StatusCode !== 200 && videoData[3]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[3]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[3]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[3]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video  text-center" *ngIf="(channelcountchange==2)&&videoData?.length>=5" [ngClass]="{'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[4].StatusCode == 200 && videoData[4]">
                        <label [id]="videoData[4].Id" class="channel_txt">{{videoData[4]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[4]?.BlobUrl" (dblclick)="fullScreen('video5')" id="selectedVideo{{videoData[4]?.ChannelName}}" (drop)="allowDrop('drop5',videoData[4]?.ChannelName,videoData[4].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[4].StatusCode !== 200 && videoData[4]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[4]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[4]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[4]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video  text-center" *ngIf="(channelcountchange==2) && videoData?.length>=6" [ngClass]="{'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[5].StatusCode == 200 && videoData[5]">
                        <label [id]="videoData[5].Id" class="channel_txt">{{videoData[5]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[5]?.BlobUrl" id="selectedVideo{{videoData[5]?.ChannelName}}" (dblclick)="fullScreen('video6')" (drop)="allowDrop('drop6',videoData[5]?.ChannelName,videoData[5].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[5].StatusCode !== 200 && videoData[5]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[5]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[5]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[5]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video text-center" *ngIf="(channelcountchange==2) && videoData?.length>=7" [ngClass]="{'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[6].StatusCode == 200 && videoData[6]">
                        <label [id]="videoData[6].Id" class="channel_txt">{{videoData[6]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[6]?.BlobUrl" id="selectedVideo{{videoData[6]?.ChannelName}}" (dblclick)="fullScreen('video7')" (drop)="allowDrop('drop7',videoData[6]?.ChannelName,videoData[6].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[6].StatusCode !== 200 && videoData[6]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[6]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[6]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[6]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video  text-center" *ngIf="(channelcountchange==2) && videoData?.length>=8" [ngClass]="{'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[7].StatusCode == 200 && videoData[7]">
                        <label [id]="videoData[7].Id" class="channel_txt">{{videoData[7]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[7]?.BlobUrl" id="selectedVideo{{videoData[7]?.ChannelName}}" (dblclick)="fullScreen('video8')" (drop)="allowDrop('drop8',videoData[7]?.ChannelName,videoData[7].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[7].StatusCode !== 200 && videoData[7]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[7]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[7]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[7]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>

                    <div class="video  text-center" *ngIf="(channelcountchange==2) && videoData?.length>=9" [ngClass]="{'col-md-4':(channelcountchange==2)&&videoData?.length>=5}">
                      <div *ngIf="videoData[8].StatusCode == 200 && videoData[8]">
                        <label [id]="videoData[8].Id" class="channel_txt">{{videoData[8]?.ChannelName}}</label>
                        <video [muted]="true" [src]="videoData[8]?.BlobUrl" id="selectedVideo{{videoData[8]?.ChannelName}}"
                               (dblclick)="fullScreen('video9')" (drop)="allowDrop('drop9',videoData[8]?.ChannelName,videoData[8].Id)" (dragover)="false" class="media-responsive video_player">
                          Your browser does not support HTML5 video.
                        </video>
                      </div>
                      <div *ngIf="videoData[8].StatusCode !== 200 && videoData[8]" class="bg_black_vd modal_video_bigbox" style="width: auto; height: 34vh;">
                        <p class="channel_txt bg_black">{{videoData[8]?.ChannelName}}</p>
                        <div class="videoprogress">
                          <i class="fa fa-video"></i>
                          <p class="channel_txt">{{videoData[8]?.StatusMessage}}</p>
                        </div>
                        <video [src]="videoData[8]?.BlobUrl" type="video/mp4" style="width:100%; height:100%;"></video>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="height: calc(100vh - 80px) !important;" class="col-md-2 pr-0 pt-05 pl-05 pb-3 small_videos card-scroll" [hidden]="isDisplay">
                  <div id="thumbcarousel" class="carousel slide" data-interval="false">
                    <div class="carousel-inner card-scroll">
                      <div class="item active" *ngFor="let video of videoData; index as i">
                        <div *ngIf="video.StatusCode == 200">
                          <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{video?.ChannelName}}">
                            <span class="channelCount channel_txt">{{video?.ChannelName}}</span>
                            <video id="selectedVideo" class="thumb_dimen no-p media-responsive" [src]="video?.BlobUrl" draggable="true" (dragstart)="drag(video)"></video>
                          </div>
                        </div>
                        <div *ngIf="video?.StatusCode !== 200">
                          <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{video?.ChannelName}}">

                          <!--<span class="channelCount channel_txt">{{item?.ChannelName}}</span>-->
                            <p class="channel_txt">{{video?.ChannelName}}</p>
                            <label class="channel_name2">{{video?.StatusMessage}}</label>
                            <video id="selectedVideo" class="thumb_dimen no-p media-responsive" [src]="video?.BlobUrl" draggable="true" (dragstart)="drag(video)"></video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <!--<div class="modal-body card-scroll p-1">
          <div class="row" *ngIf="videoDataLength==1">
            <span class="channelCount">{{video?.ChannelName}}</span>
            <video class="video_player" (dblclick)="fullScreen()" vidz style="width:100%;">
              <source [src]="videoData[0]?.BlobUrl" alt="Video Not Found" type="video/mp4">
            </video>
          </div>
          <div class="row" *ngIf="videoDataLength==2">
            <div class="col" style="padding-left: 6px; padding-right: 2px;" *ngFor="let video of videoData; index as i">
              <label  [id]="'drop'+1" class="channelCount">{{video?.ChannelName}}</label>
              <video class="video_player" (dblclick)="fullScreen()" #videoPlayer (ended)="vidEnded()" (drop)="drop(i)" (dragover)="allowDrop('drop9')" style="width:100%;">
                <source [src]="video?.BlobUrl" type="video/mp4">
              </video>
            </div>
          </div>
          <div *ngIf="videoDataLength==3">
            <div class="row">
              <div class="col" *ngFor="let video of videoData; index as i">
                <div *ngIf="video?.BlobUrl!=null">
                  <label class="channelCount">{{video?.ChannelName}}</label>
                  <video class="video_player" controls (dblclick)="fullScreen()" #videoPlayer (ended)="vidEnded()" (drop)="drop(i)" (dragover)="allowDrop('drop9')" style="width:100%;">
                    <source [src]="video?.BlobUrl" alt="Video Not Found" type="video/mp4">
                  </video>
                </div>
                <div class="col" *ngIf="video?.BlobUrl==null">
                  <label>Url Not Available</label>
                  <video class="video_player" controls style="width:100%;">
                    <source [src]="video?.BlobUrl" alt="Video Not Found" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="videoDataLength==4">
            <div class="row">
              <div class="col" *ngFor="let video of videoData">
                <span class="channelCount">{{video?.ChannelName}}</span>
                <video class="video_player" (dblclick)="fullScreen()" style="width:100%;">
                  <source [src]="video?.BlobUrl" alt="Video Not Found" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
          <div *ngIf="videoDataLength>4">
            <div class="row">
              <div *ngFor="let video of videoData; let i=index">
                <div class="col" *ngIf="i==0">
                  <video class="video_player" (dblclick)="fullScreen()" style="width:100%; height:200%">
                    <source [src]="video?.BlobUrl" alt="Video Not Found" type="video/mp4">
                  </video>
                </div>
                <div class="col" *ngIf="i>0">
                  <video controls style="width:100%;" (dblclick)="fullScreen()" >
                    <source [src]="video?.BlobUrl" alt="Video Not Found" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2 pr-0 pt-05 pl-05 pb-05">
            <div id="thumbcarousel" class="carousel slide" data-interval="false">
              <div class="carousel-inner card-scroll">
                <div class="item active" *ngFor="let video of videoData; index as i">
                  <div *ngIf="video?.BlobUrl!=null">
                    <div class="thumb  hvr-grow-shadow incident_thumb" id="drag{{i}}">
                      <span class="channelCount">{{video?.ChannelName}}</span>
                      <video id="video{{i}}" class="thumb_dimen no-p media-responsive" [src]="video?.BlobUrl" draggable="true" (dragstart)="drag(video)"></video>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
          <div class="modal-footer modal_footer_position mobile_controls justify-content-center">
            <!--<div class="m-auto">
            <a class="btn btn-success mr-1" *ngIf="playVideo" (click)="playVid()">Play</a>
            <a class="btn btn-success mr-1" *ngIf="!playVideo" (click)="pauseVid()">Pause</a>
            <a class="btn btn-success mr-1" (click)="backVideo(5)"><i class="far fa-backward"></i></a>
            <a class="btn btn-success mr-1" (click)="forVideo(5)"><i class="far fa-forward"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="maxVideo" (click)="maxmize()"><i class="fas fa-expand"></i></a>
            <a class="btn btn-success mr-1 ml-05" *ngIf="!maxVideo" (click)="minimize()"><i class="fas fa-expand"></i></a>
          </div>-->


            <div class="col-lg-8 col-md-6 col-sm-6 col-6 mobile_controls_left">
              <div class="d-flex pl-35 pt-06">
                <a class="btn btn-success mr-1 mobile_controls_play_btn" *ngIf="playVideo" (click)="playVid()">Play</a>
                <a class="btn btn-success mr-1" *ngIf="!playVideo" (click)="pauseVid()">Pause</a>
                <div class="mr-05 pt-05 mobile_controls_font_10">
                  <span id="time"></span>/<span id="duration"></span>
                </div>
                <div class="video_bar">
                  <input type="range" id="inputSeek" value="0">
                  <!--<input class="seekbar" type="range" min="0" max="100">-->
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mobile_controls_right">
              <div class="m-auto">
                <a class="btn btn-success mr-1" (click)="backVideo(5)">
                  <i class="far fa-backward"></i>
                </a>
                <a class="btn btn-success mr-1" (click)="forVideo(5)">
                  <i class="far fa-forward"></i>
                </a>

                <div class="mute_ddldropdown d-inline-block">
                  <a class="btn btn-success mr-1 dropdown-toggle" (click)="muteOptions()">
                    <i class="fas fa-volume-mute" *ngIf="muteButton"></i>
                    <i class="fas fa-volume" *ngIf="!muteButton"></i>
                  </a>

                  <div class="mute_ddl controlspopup" *ngIf="showpopup">
                    <div class="mute_head d-flex">
                      <h5 class="modal-title">Mute Options</h5>
                      <a class="mute_close" (click)="muteOptionsClose()"> <i class="fal fa-times"></i></a>
                    </div>
                    <div class="mute_body card-scroll">
                      <div class="row no-m mb-1" *ngFor="let data of videoData; index as i">
                        <div class="col-md-6 col-sm-6 col-6 no-p text-left">
                          <a href="#">{{data?.ChannelName}}</a>
                        </div>
                        <div class="col-md-6 col-sm-6 col-6 no-p text-right">
                          <!--<label class="switch switch-small">
                          <input type="checkbox" ngChange="muteVideo1(data.ChannelName)" [(ngModel)]="data.IsMuted">
                          <span class="slider"></span>
                        </label>-->

                          <label class="switch">
                            <input type="checkbox"
                                   [checked]="data.IsMuted"
                                   [(ngModel)]="data.IsMuted"
                                   (change)="muteVideo1(data.ChannelName)" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--<a class="btn btn-success mr-1" *ngIf="maxVideo" (click)="maxmize()">
                <i class="fas fa-expand"></i>
              </a>
              <a class="btn btn-success mr-1" *ngIf="!maxVideo" (click)="minimize()">
                <i class="fas fa-expand"></i>
              </a>-->
                <a *ngIf="toggleClass === 'ft-maximize'" class="btn btn-success mr-1" href="javascript:;" (click)="openFullscreen4()">
                  <i class="fas fa-expand"></i>
                </a>
                <a *ngIf="toggleClass === 'ft-minimize'" class="btn btn-success mr-1" href="javascript:;" (click)="closeFullscreen()">
                  <i class="fas fa-compress"></i>
                </a>
                <a class="btn btn-success ml-05" (click)="DownloadVideos(videoData)">
                  <i class="fas fa-download"></i>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


<div class="modal fade modal-custom-sm" id="filters" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog mobile_modal_videos" role="document" style="width: 60%">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Filters</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" tooltip="Close" placement="left" [hide-delay]="0" [z-index]="2000" #IncidentFileterCloseButton>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body card-scroll filters mobile_modal_card_scroll">
        <!--<div class="col-4 no-p">
          <ng-multiselect-dropdown [placeholder]="'Select Fleet'"
                                   [data]="filters?.EventRecordType"
                                   class="form-control"
                                   [(ngModel)]="selectedFleets"
                                   [settings]="dropdownSettings">
          </ng-multiselect-dropdown>
        </div>-->
        <!--<h5>Record Type</h5>
        <div *ngFor="let record of filters.EventRecordType">
          <label class="checkbox">
            <input type="checkbox" [(ngModel)]="record.status">
            <span>{{record?.Name}}</span>
          </label>
        </div>-->
        <h5>Severity</h5>
        <div class="row">
          <div class="col-3" *ngFor="let severity of filters?.SeverityFilter">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="severity.FilterChecked">
              <span>{{severity?.Name}}</span>
            </label>
           

          </div>
        </div>
        <h5>Classifications</h5>
        <div class="row">
          <div class="col-3" *ngFor="let clsf of filters?.clasificationList">
            <label class="checkbox">
              <input type="checkbox" [(ngModel)]="clsf.FilterChecked">
              <span>{{clsf?.Classification}}</span>
            </label>
          </div>
        </div>
        <h5>Event Groups</h5>
        <div class="row">
          <div class="col-12" *ngFor="let group of filters?.EventTypesFilter;index as i">
            <div class="fleetNamesSection border-0 mb-1 filter_fleetNamesSection">
              <label class="checkbox">
                <input type="checkbox" [(ngModel)]="group.AllChecked" (change)="EventTypesCheckAll(group)" [disabled]="group.status==false">
                <span>{{group?.EventTypeGroupName}}</span>
              </label>
              <span class="material-switch mb-1 float-right">
                <input id="switch-lable{{i+1}}" name="someSwitchOption001" type="checkbox" [(ngModel)]="group.FilterChecked" (change)="EventGroupTypeChange(group)" />
                <label for="switch-lable{{i+1}}" class="bg-primary"></label>
              </span>
            </div>
            <div class="row no-m">
              <div class="py-2 col-md-4 col-6" *ngFor="let type of group.Types">
                <label class="checkbox">
                  <input type="checkbox" [(ngModel)]="type.FilterChecked" [disabled]="group.FilterChecked==false" (change)="optionToggled(group)">
                  <span>{{type?.Name}}</span>
                </label>
                <span class="float-right">
                  <i class="far fa-bell" *ngIf="type.ClassificationTypeId==1" tooltip="Event" placement="left" [hide-delay]="0"></i>
                  <i class="far fa-info-circle" *ngIf="type.ClassificationTypeId==2" tooltip="Info" placement="left" [hide-delay]="0"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="livefoot">
        <div class="col no-p">
          <button class="btn btn-success pull-right" [disabled]="filterload" (click)="changeStatus()">Apply</button>
          <!--  <button class="btn btn-success pull-right" [disabled]="filterload" (click)="customVideoFun(customdatefilter)" *ngIf="dateStatus==CustomDateFunctionalityValue">Apply</button>-->
          <img src="../../assets/images/ButtonProcessing.gif" *ngIf="filterload" />
        </div>
      </div>
    </div>
  </div>
</div>

</div>
