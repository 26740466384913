<div class="pre-loader" *ngIf="preloader==true">
  <div class="load-icon">
    <div class="ring-load">
      <div class="circle"></div>
      <!--<img src="assets/images/Favicon.svg" />-->
      <div class="pageload-icon"></div>
    </div>
  </div>
</div>

<div class="row reports">
  <div class="col-lg-3 col-md-12 col-sm-12 col-12 no-p viewSection card-scroll-full" style="overflow:hidden !important;">
    <div class="lb-head">
      <h6 class="no-p pull-left">Reports</h6>
      <input type="text" class="form-control col-12" placeholder="Search" [(ngModel)]="searchReports" />
    </div>
    <div class="card-scroll">
      <ul class="nav">
        <li class="media p-1 row" *ngFor="let item of reportCategories | searchFilter: { Name:searchReports}">
          <h6 class="col-10 no-p">{{item.Name}}</h6>
          <a class="col-2 no-p text-right view-icon" (click)="getReports(item.Id,item.Name)">
            <i class="far fa-arrow-alt-circle-right" tooltip="View" placement="left" [hide-delay]="0" [ngClass]="{'eventactive':selectedid==item.Id}"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-lg-9 col-md-12 col-sm-12 col-12 no-p shadow card-scroll-full">
    <div class="ReportSummarySection" *ngIf="ReportSummarySection">
      <div class="lb-head-single">
        <h6 class="no-p pull-left">{{activeReportName}}</h6>
      </div>
      <div class="" id="summary">
        <div class="row no-m">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 no-p" *ngFor="let item of ReportsList">
            <div class="panel row">
              <div class="col-12 p-3">
                <div class="{{item.IconURL}} panelbg panel-blue"> </div>
                <div class="panel-info">
                  <h4 class="panel-title">{{item.Name}}</h4>
                  <h4 class="panel-childs">{{item.Tagline}} | {{item.Description}}</h4>
                </div>
              </div>
              <div class="panel-footer col-12">
                <p class="no-m pull-right" (click)="viewReport(item.Id,item.TemplateURL,item.Name)">View Report <i class="far fa-angle-double-right"></i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ViewReportSection" *ngIf="ViewReportSection">
      <div class="lb-head-single">
        <div class="col-md-3 col-12 p-0">
          <a class="pull-left mr-2" (click)="closeViewReport()" tooltip="Close" placement="left"><i class="far fa-arrow-alt-left view_icon no-m"></i></a>
          <h6>View Report</h6>
        </div>
      </div>
      <div class="row no-m">
        <form class="col-md-3 col-12 no-p" name="applyReport" (ngSubmit)="getReport()" [formGroup]="viewReportForm">
          <div class="col-12 p-2" style=" box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.3); height:calc(100vh - 40px)">
            <div class="form-group" *ngIf="reportStructure.AllFleets==true">
              <label>Select Fleet</label>
              <select class="form-control" formControlName="FleetId" [(ngModel)]="AllFleets" (change)="onFleetChange(AllFleets)">
                <option value="AllFleets">All fleets</option>
                <option *ngFor="let item of fleets; index as i" value="{{item.Id}}">{{item.Name}}</option>
              </select>
            </div>
            <div class="form-group" *ngIf="reportStructure.AllVehicles==true">
              <div class="form-group">
                <div class="checkspan">
                  <span class="checkbox">
                    <label class="d-flex">
                      <input type="checkbox" class="form-control checkbox_coustom" formControlName="AllVehicles" [(ngModel)]="AllVehicles">
                      <span class="checkbox-material"><span class="check"></span></span>All Vehicles
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="reportStructure.MultipleVehicles==true" [hidden]="AllVehicles">
              <span class="checkbox">
                <label>
                  <input type="checkbox" class="form-control checkbox_coustom" formControlName="MultipleVehicles" [attr.disabled]="MultipleVehicles" [(ngModel)]="MultipleVehicles">
                  <span class="checkbox-material"><span class="check"></span></span>One or more vehicles/vehicle groups
                </label>
              </span>
              <select class="form-control" formControlName="MultipleVehiclesSelect" [(ngModel)]="selectedVehicle" (change)="MultiVehicles(selectedVehicle)">
                <option value="" selected>Select Vehicle</option>
                <option *ngFor="let item of vehicles" [ngValue]="item">{{item.VRN}},{{item.DeviceIdentifier}}</option>
              </select>
              <div class="panel-group mt-1 card-scroll" style="height: auto;margin-bottom: 0px;max-height: 100px;" *ngIf="selectedVehicleList.length!=0">
                <div class="panel panel-default">
                  <div class="panel-body append_value" id="items">
                    <p *ngFor="let item of selectedVehicleList">{{item.VRN}}<i class="fas fa-times pull-right" (click)="removeSelectedVrn(item.DeviceIdentifier)"></i></p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="reportStructure.SingleVehicle==true">
              <h5 class="mt-2">Vehicles</h5>
              <p>Select the vehicles to recieve notifications for. </p>
              <div class="form-group">
                <select class="form-control" name="SelectVehicle" formControlName="VehicleId">
                  <option value="" selected>Select Vehicle</option>
                  <option *ngFor="let item of vehicles" [ngValue]="item">{{item.VRN}},{{item.DeviceIdentifier}}</option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="reportStructure.StartDate==true">
              <label for="StartDate">Start Date</label>
              <input type="text" class="form-control"
                     formControlName="StartDate"
                     name="StartDate"
                     required
                     [maxDate]="maxDate"
                     bsDatepicker
                     [(ngModel)]="StartDate"
                     [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',isAnimated: true,showWeekNumbers:false}" />
            </div>
            <div class="form-group" *ngIf="reportStructure.EndDate==true">
              <label for="EndDate">End Date</label>
              <input type="text" class="form-control"
                     formControlName="EndDate"
                     name="EndDate"
                     required
                     [maxDate]="maxDate"
                     bsDatepicker
                     [(ngModel)]="EndDate"
                     [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD',isAnimated: true,showWeekNumbers:false}" />
            </div>
            <div class="form-group" *ngIf="reportStructure.Date==true">
              <label for="Date">Date</label>
              <input type="text" class="form-control" formControlName="Date" name="Date" required [maxDate]="maxDate" bsDatepicker
                     [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',isAnimated: true,showWeekNumbers:false}" />
            </div>
            <div class="form-group" *ngIf="reportStructure.TimeZone==true">
              <label>Time Zone</label>
              <select class="form-control" formControlName="TimeZone" [(ngModel)]="usertimezone">
                <option *ngFor="let item of TimeZones" value="{{item.Id}}">{{item.Id}}</option>
              </select>
            </div>
            <div *ngIf="reportStructure.EventType==true">
              <label>Events</label>
              <div class="form-group">
                <select class="form-control" name="EventType" formControlName="EventType" [(ngModel)]="EventType" [ngClass]="{'disabled':reporttype == '/Report/EmergencyEvents'}">
                  <option value="AllEvents" selected>All</option>
                  <option *ngFor="let item of EventTypeList" value="{{item.id}}">{{item.name}}</option>
                </select>
              </div>
            </div>
            <div *ngIf="reportStructure.SeverityType==true">
              <lable>Severity</lable>
              <div class="form-group">
                <select class="form-control" name="Severity" formControlName="Severity" [(ngModel)]="Severity" [ngClass]="{'disabled':reporttype == '/Report/EmergencyEvents'}">
                  <option value="AllSeverity" selected>All</option>
                  <option value="3">High</option>
                  <option value="2">Medium</option>
                  <option value="1">Low</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-success float-right">Apply</button>
            </div>
          </div>
        </form>
        <div class="col-md-9 col-12 card-scroll-single p-3"  style="
    height: calc(100vh - 80px);" >
          <div id="pdfTable" #pdfTable>
            <h5 class="table_h5_title text-center bg-light-grey mb-2">{{reportName}}</h5>
            <div *ngIf="reporttype=='/Report/FleetHealth'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5 class="">No Records <span>Found.!</span></h5>
              </div>

              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}
                    </td>

                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.fleetHealthList,reporttype)"><i class="fas fa-download"></i></button>

                    </td>-->
                  </tr>
                  <tr class="bg_tabel_head">
                    <td>VRN</td>
                    <td>DeviceIdentifier</td>
                    <td>LastUpdated</td>
                    <td>DeviceName</td>
                    <td>Is GPS</td>
                    <td>Recording</td>
                    <td>Media</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let fleet of item.fleetHealthList">
                    <td>{{fleet.VRN}}</td>
                    <td>{{fleet.DeviceIdentifier}}</td>
                    <td>{{fleet.LastUpdatedDate}}</td>
                    <td>{{fleet.ModelName}}</td>
                    <td>{{fleet.GpsValid}}</td>
                    <td>{{fleet.Recording}}</td>
                    <td>{{fleet.Media}}</td>
                    <td>{{fleet.Address}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/EventSummary'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="p-1 no-boder table_title" colspan="7">
                      {{item.FleetName}}-{{item.EventDateString}}-{{item.VRN}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.list,reporttype)"><i class="fas fa-download"></i></button>

                    </td>-->
                  </tr>
                
                  <tr class="bg_tabel_head">
                    <td>EventType</td>
                    <td>Count</td>
                  </tr>
                  <tr *ngFor="let cnt of item.list">
                    <td>{{cnt.EventType}}</td>
                    <td>{{cnt.count}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/EmergencyEvents'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>

                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.EventDateString}}-{{item.VRN}}-{{item.DriverName}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>

                    </td>-->
                  </tr>
                  <tr class="bg_tabel_head">
                    <td>Time</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>Severity</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.SeverityTypeString}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/Events'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.EventDateString}}-{{item.VRN}}-{{item.DriverName}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>

                    </td>-->
                  </tr>

                  <!--<tr>{{item.FleetName}}-{{item.EventDateString}}-{{item.VRN}}-{{item.DriverName}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>Time</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>Severity</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}} {{cnt.Distance}}</td>
                    <td>{{cnt.SeverityTypeString}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/TripScoreDetail'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.DriverName}}-{{item.Date}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.tripScores,reporttype)"><i class="fas fa-download"></i></button>

                    </td>-->
                  </tr>

                  <!--<tr>{{item.FleetName}}-{{item.DriverName}}-{{item.Date}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>VRN</td>
                    <td>Start Loc</td>
                    <td>End Loc</td>
                    <td>Travel Time</td>
                    <td>Acc Score</td>
                    <td>Speed Score</td>
                    <td>Break Score</td>
                    <td>Turn Score</td>
                    <td>Total Score</td>
                  </tr>
                  <tr *ngFor="let cnt of item.tripScores">
                    <td>{{cnt.VRN}}</td>
                    <td>{{cnt.StartLocation}}</td>
                    <td>{{cnt.EndLocation}}</td>
                    <td>{{cnt.TripsDurationMin}} min</td>
                    <td>{{cnt.AccelScore}}</td>
                    <td>{{cnt.SpeedScore}}</td>
                    <td>{{cnt.BrakeScore}}</td>
                    <td>{{cnt.TurningScore}}</td>
                    <td>{{cnt.TotalTripScore}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/TripScore'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr class="bg_tabel_head">
                    <td>FleetName</td>
                    <td>Driver</td>
                    <td>Travel Time</td>
                    <td>Acc Score</td>
                    <td>Speed Score</td>
                    <td>Break Score</td>
                    <td>Turn Score</td>
                    <td>Total Score</td>
                  </tr>
                  <tr *ngFor="let cnt of item.tripScores">
                    <td>{{cnt.FleetName}}</td>
                    <td>{{cnt.DriverName}}</td>
                    <td>{{cnt.TripsDurationMin}} min</td>
                    <td>{{cnt.AccelScore}}</td>
                    <td>{{cnt.SpeedScore}}</td>
                    <td>{{cnt.BrakeScore}}</td>
                    <td>{{cnt.TurningScore}}</td>
                    <td>{{cnt.TotalTripScore}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/Incidents'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->

                  </tr>


                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->

                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>FaultCategory</td>
                    <td>FaultReason</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.FalutCategory}}</td>
                    <td>{{cnt.FaultReason}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/Accidents'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->
                  </tr>

                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->

                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>FaultCategory</td>
                    <td>FaultReason</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.FalutCategory}}</td>
                    <td>{{cnt.FaultReason}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/Dismissed'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>

                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->
                  </tr>


                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/Intervention'">

              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->
                  </tr>


                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>FaultCategory</td>
                    <td>FaultReason</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.FalutCategory}}</td>
                    <td>{{cnt.FaultReason}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/FalsePositive'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>

                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->
                  </tr>

                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>FaultReason</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.FaultReason}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="reporttype=='/Report/DriverFalult'">
              <div *ngIf="reportData.length==0" class="tab-alert no-records">
                <h5>No Records <span>Found.!</span></h5>
              </div>
              <table *ngFor="let item of reportData" class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td class="table_title p-1 no-boder" colspan="7">
                      {{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}
                    </td>
                    <!--<td class="text-center">
                      <button class="btn btn-success  mt-05 mb-05" *ngIf="reportData.length!=0" (click)="pdfDispatchDataTable(item.eventsData,reporttype)"><i class="fas fa-download"></i></button>
                    </td>-->
                  </tr>

                  <!--<tr>{{item.FleetName}}-{{item.VRN}}-{{item.DriverName}}-{{item.EventDateString}}</tr>-->
                  <tr class="bg_tabel_head">
                    <td>Date</td>
                    <td>Type</td>
                    <td>Speed</td>
                    <td>FaultReason</td>
                    <td>AuditBy</td>
                    <td>Address</td>
                  </tr>
                  <tr *ngFor="let cnt of item.eventsData">
                    <td>{{cnt.EventDateString}}</td>
                    <td>{{cnt.EventTypeString}}</td>
                    <td>{{cnt.spd}}</td>
                    <td>{{cnt.FaultReason}}</td>
                    <td>{{cnt.AuditBy}}</td>
                    <td>{{cnt.LocationAddress}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
         <button class="btn btn-success float-right mt-05 mb-05" *ngIf="reportData.length!=0" (click)="downloadPDF('pdfTable')"><i class="fas fa-download"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>


