import { Component, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit, EventEmitter, Output, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { formatDate, DatePipe, CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { ConstantsService } from '../services/constants.service';
//import { EventMapService } from '../services/event-map.service'
declare var jQuery: any;
import { NgIf, NgClass } from '@angular/common';
import { SearchFilterPipe } from '../customPipes/searchPipe/search-filter.pipe';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewMapComponent } from '../view-map/view-map.component';

@Component({
  selector: 'app-videos',
  standalone: true,
  imports: [NgIf, NgClass, CommonModule, SearchFilterPipe, FormsModule, NgxPaginationModule
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule, ViewMapComponent],
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit, AfterViewInit {
  collp: boolean = true;
  dateData: string;
  maxVideo: boolean = true;
  videosList: any = [];
  colp: boolean = true;
  videos: any = "hello";
  EmittingData: any;
  videoEventId: any;
  expand: boolean = false;
  displayStretch = false;
  channelcountchange = 2;
  videoCustomDate: any;
  blobLength: any = [];
  playVideo: boolean = true;
  record_ = [];
  record1 = [];
  dropdownSettings1 = {};
  hidePlay: boolean = false;
  BlobLength: any;
  videoDataLength: any;
  driver: any;
  maxClass: boolean = false;
  servercommandVideo: any;
  //servervideo: any = [];
  //servervideoList: any = [];
  videoData: any = [];
  servercommandVideoList: any = [];
  selectedSingleVideo = {};
  preloader = false;
  searchVideos: string;
  selectedLevel: string = "1";
  videosData: any;
  totalLength = 0;
  itemsPerPage = 20;
  selectedImage;
  page: number = 1;
  eventicon;
  customdatefilter: any;
  customdate_: any;
  locale = 'en-US';
  selectedIncidentObj: any;
  CustomDateFunctionalityValue: string = "9";
  selectedClassification: string = "0";
  selectedCategory: string = "0";
  selectedReason: string = "0";
  filters: any = {};
  MediaGalleryActiveTab: string;
  filterload: boolean = false;
  customdate: boolean = false;
  fleetFilter: string;
  classificationFilter: string;
  eventtypeFilter: string;
  severityFilter: string;
  defaultAssigned: boolean = false;
  ActivatedFilter: boolean = false;
  reqsubmitted: boolean = false;
  submitManReq: boolean = false;
  createReqSec: boolean = false;
  managerlist: any;
  driverlist: any;
  dateStatus: any = "3";
  status: any = 3;
  videoLength: any;
  reqreceipient: string;
  selectedFleets = [];
  incidentServercommands = [];
  dropdownfleetlist: any;
  commandVideoList = [];
  maxDate = new Date();
  minDate = new Date();
  dateformat = 'yyyy-MM-dd';
  AnalyzeListPoints = [];
  selectedIncidentId;
  loadingmsg: boolean = false;
  classification: any = [];
  serverCommand: any;
  incidetData: any;
  VideoInfo: any = {};
  EvidenceFileList: any;
  statusId: any = [];
  FleetId = localStorage.getItem('FleetId');
  videosDetails: any;
  MyListIncidents: number = 2;
  isDisplay = false;
  dragingurl: string;
  isPlay: boolean = false;
  SkipButtons: boolean = false;
  PlayButton: boolean = true;
  AllVehicles = true;
  selectedVehicleList = [];
  selectedVehicle = null;
  vehiclesByFleet = [];
  elem;
  stretch = false;
  toggleClass = 'ft-maximize';
  pipe = new DatePipe('en-US');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });
  ngAfterViewInit() {
    // @ViewChild('vidz', { static: true }) vid: ElementRef;
    // @ViewChild('vidz', { static: true }) new ElementRef<any>() ;
  }
  @ViewChild('fullScreen') divRef;
  @ViewChild('IncidentFileterCloseButton', { static: true }) IncidentFileterCloseButton: ElementRef;
  // @Output() mapView = new EventEmitter <string>();
  // @Input()
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  @ViewChild('videoPlayer') evidenceVideoPlayer: ElementRef;

  constructor(private http: HttpClient, private constants: ConstantsService, private router: Router, private toastrService: ToastrService) {

  }
  eventStatusList: Array<Object> = [
    { id: 1, name: "Today" },
    { id: 2, name: "Yesterday" },
    { id: 3, name: "Current Week" },
    { id: 4, name: "Last Week" },
    { id: 9, name: "Custom" }
  ];
  mylistCategory(status) {
    this.MyListIncidents = status;
    this.selectedIncidentId = null;
    this.ActivatedFilter = false;
    if (this.selectedLevel != this.CustomDateFunctionalityValue) {
      this.getAllEvents(this.dateStatus);
    } else {
      this.getAllEvents(this.customdate_);
    }
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.preloader = true;
    // this.getAllEvents(this.dateStatus);
    this.getFilters();

    // console.log("it is reading here");
    // this.colp;
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: 'DeviceIdentifier',
      textField: 'VRN',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      enableCheckAll: false
    };
    this.constants.disableDiv();
  }
  
  ngOnchanges() {
    //this.viewEventMap(this.videoEventId);
  }
  
  viewEventMap(video: any) {
    console.log("it s reaing here");
    // console.log(video, "Video EventId")
    this.constants.Viewmap_Str = "Video";
    this.videoEventId = video;
    this.colp = false;
    this.constants.enableDiv();
    // this.colp = this.eventService.colp;
    //  console.log(this.colp,"EventData test")
    //console.log(video.EventId, "EventId")
    ////this.videoEventId = video.EventId
    //this.EmittingData = this.mapView.emit(video);
    //this.colp=true ;
  }
  viewValueMap(value: any) {
    //console.log(value, "viewValueMap EventId")
    this.colp = value
  }
  callVideoEvent(value) {
    console.log(value, "after submit callVideoEvent Listening");
    this.getAllEvents(value);
    console.log("callVideoEvent Listening");

  }
  getAllEvents(date) {
    console.log(date, "DAte");
    this.dateData = date;
    this.preloader = true;
    var DeviceString = "";
    if (this.dateData == "1" || this.dateData == "2" || this.dateData == "3" || this.dateData == "4") {
      this.customdate = false;
    }
    if (this.dateData == "9" && !this.AllVehicles) {
      this.loadingmsg = this.preloader = false;
      this.toastrService.error('please select date range');
      this.videosDetails = [];
      return;
    }
    if (!this.AllVehicles && this.selectedVehicleList.length === 0) {
      this.loadingmsg = this.preloader = false;
      this.toastrService.error('please select vehicle');
      this.videosDetails = [];
      return;
    }
 /*   debugger;*/
    if (!this.AllVehicles && this.selectedVehicleList.length !== 0) {
      this.selectedVehicleList.forEach((x: any, y) => {
        DeviceString = DeviceString + x.DeviceIdentifier + ',';
      })
    } else
      DeviceString = null;
    let obj: any = {
      "datestring": date,
      "eventtypes": this.eventtypeFilter,
      "recordtypes": "1,4,6",         //this not mandatory field
      "classificationtypes": this.classificationFilter,
      "severitytypes": this.severityFilter,
      "selectedFleetId": this.FleetId,
      "pageindex": this.page,
      "pagesize": this.itemsPerPage,
      "DeviceString": DeviceString,
      "StatusTypes": "1,2,3,5" // filter types of video status

    };
    this.http.put(environment.API_URL + "Device/GetAllVideoList",obj
      , { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          /*debugger;*/
          this.loadingmsg = this.preloader = false;
          this.videosDetails = data.ServerCommands;
          this.totalLength = data.TotalPageCount;
          //this.selectedVehicle = null;
        },
        error => {
          this.preloader = false;
          //this.toastrService.error("Error", error);
          this.videosDetails = [];
        })
  };
  pageChanged(x) {
    this.page = x;
    this.changeStatus(x);
  };
  customVideoFun(customdate: any) {
    this.colp = true;
    if (customdate !== null) {
      this.customdatefilter = customdate;
      var from = customdate[0];
      var to = customdate[1];;
      var date1 = new Date(from);
      var date2 = new Date(to);
      console.log(date1, date2);
      var diffTime = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays);
      if (diffDays <= 7) {
        this.customdate_ = this.pipe.transform(date1, 'yyyy-MM-dd') + "," + this.pipe.transform(date2, 'yyyy-MM-dd');
        this.changeStatus();
      } else {
        this.toastrService.error("Date range more than 7 days can't supported, Please select valid date range.");

      }
    }
  }
  ClearFilters() {
    this.ActivatedFilter = false;
    // this.searchVideos = "";
    this.videoCustomDate = ""
    //  //this.selectedFleets = [];
    //  //if (this.selectedLevel != this.CustomDateFunctionalityValue) {
    //  //  this.getAllEvents(this.dateStatus);
    //  //} else {
    //  //  this.getAllEvents(this.customdate_);
    //  //}
    //  this.getAllEvents(this.dateStatus);
    this.changeStatus();
    //  this.getAllEvents(this.customdate_);
  }
  viewIncident(data) {

  };
  getIncidentPath() {
    alert('show iurl')
    //  console.log("It Is calling");
  }

  //videoMedia: any = {};
  changeStatus(x = null) {
    // console.log(this.filters);
    if (x !== null) {
      this.page = x;
    } else {
      this.page = 1;
    }
    this.searchVideos = "";
    this.colp = true;
    localStorage.setItem("GetEventfilters_" + localStorage.getItem('UserName'), JSON.stringify(this.filters));
    // this.videoCustomDate = "";
    this.IncidentFileterCloseButton.nativeElement.click();
    //console.log(this.eventsCustomDate, this.customdate_);
    var eventtype = this.filters.EventTypesFilter.filter((item) => { return item.FilterChecked === true });
    var classification = this.filters.clasificationList.filter((item) => { return item.FilterChecked === true });
    var severity = this.filters.SeverityFilter.filter((item) => { return item.FilterChecked === true });
    var eventString = "", classificationString = "", severityString = "";
    //console.log('severity', severity);
    //  console.log('classification', classification);
    //  console.log('eventtype', eventtype);
    var Types = [];
    this.filters.clasificationList.forEach(item => {
      if (item.FilterChecked === true && classification != undefined) {
        classificationString += item.Id + ",";
      }
    });
    //severity list
    this.filters.SeverityFilter.forEach(item => {
      if (item.FilterChecked === true && severity != undefined) {
        severityString += item.Id + ",";
      } else if (severity == undefined) {
        //severityString += item.Id + ",";
        severityString = "3";
      }
    });
    //event type list
    this.filters.EventTypesFilter.forEach(item => {
      if (item.FilterChecked === true) {
        item.Types.forEach(type => {
          Types.push(type);
        });
      }
    });
    Types.forEach(item => {
      if (item.FilterChecked) {
        eventString += item.Id + ",";
      }
    });
    this.eventtypeFilter = eventString;
    this.classificationFilter = classificationString;
    this.severityFilter = severityString;
    //console.log('eventtypeFilter', this.eventtypeFilter);
    //console.log('classificationFilter', this.classificationFilter);
    //console.log('severityFilter', this.severityFilter);
    if (this.dateStatus != this.CustomDateFunctionalityValue) {
      //console.log("it is radinghere");
      this.customdate_ = "";
      this.videoCustomDate = "";
      this.customdate = false;
      if (eventtype == undefined && classification == undefined && severity == undefined && this.dateStatus != this.CustomDateFunctionalityValue) {
        this.ActivatedFilter = false;
        this.fleetFilter = "";
        this.eventtypeFilter = "";
        this.classificationFilter = "";
        this.severityFilter = "";
        this.getAllEvents(this.dateStatus);
        this.filterload = false;
        return;
      }
      this.ActivatedFilter = true;
      this.getAllEvents(this.dateStatus);
    } else {
      this.customdate = true;
      // console.log("it is radinghere");
      if (this.customdate_ !== "")
        this.getAllEvents(this.customdate_);
    }
  }
  DeviceChannelCount = 0;
  getVideos(arr) {
    this.videoData = [];
    this.VideoInfo = arr;
    console.log(this.VideoInfo);
    this.videoModal = true;
    console.log(this.VideoInfo, "videoInfo");
    this.videoData = [];
    this.videoData = arr.Videos;
    this.DeviceChannelCount = arr.Videos.length;
    //this.selectedSingleVideo = this.videoData[0]; 
    this.selectedSingleVideo = this.videoData.find(x => x.BlobUrl !== null);
    //this.VideoInfo.videosList.forEach((x: any, y) => {
    //  this.videoData.push(x);
    //});
    //setTimeout(() => {

    //}, 1000)
    if (this.DeviceChannelCount > 1) {
      this.channelcountchange = 2;
    } else {
      this.channelcountchange = 1
    }
    

    if (this.channelcountchange == 2) {
      this.displayStretch = false
    } else if (this.channelcountchange == 1) {
      this.displayStretch = true
    }
    this.videoData = arr.Videos;
    this.videoData.forEach((x: any, y) => {
      x.Id = UUID.UUID();
    })

    this.videoDataLength = this.videoData.length;
    console.log(this.videoData, "videoData");
    //this.BlobLength = arr.Videos.forEach((x: any) => {
    //  x.BlobUrl.forEach((a: any) => {
    //    this.blobLength = a;
    //    console.log(this.blobLength, "BlobLength")
    //  })
    //})
    this.isDisplay = false;
    jQuery('#inputSeek').val(0);
    document.getElementById("time").innerHTML = '00:00';
    document.getElementById("duration").innerHTML = '00:00';
  }

  getFirstNonNullBlobUrl(vi: any[]): string {
    for (let i = 0; i < vi.length; i++) {
      if (vi[i].BlobUrl != null) {
        return vi[i].BlobUrl;
      }
    }
    return null;
  }

  onItemSelect(item: any) {
    console.log(item, 'onitemselect');
    this.MultiVehicles(item);
  }

  onItemDeSelect(item: any) {
    console.log(item, 'onitemdeselect');
    this.removeSelectedVrn(item);
  }

  getFilters() {
    const key = "GetEventfilters_" + localStorage.getItem('UserName');
    if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
      this.filters = JSON.parse(localStorage.getItem(key));
      this.classification = this.filters.clasificationList;
      this.changeStatus();
    } else {
      this.http.get(environment.API_URL + 'Device/GetEventFilters/' + localStorage.getItem("FleetId") + "/" + "null", { headers: this.reqHeader }).subscribe((data: any) => {
        this.filters = data;
        this.classification = this.filters.clasificationList;
        this.changeStatus();
      })
    }
    this.getVehicles(localStorage.getItem("FleetId"));
  }

  EventGroupTypeChange(data) {
    console.log(data, "EventGroupTypeChange");
    if (!data.status) {
      data.AllChecked = false;
      data.Types.forEach(x => {
        x.status = false;
      });
    }
  }
  EventTypesCheckAll(data) {
    console.log(data);
    this.filters.EventTypesFilter.forEach((x: any, y) => {
      if (x.EventTypeGroupId == data.EventTypeGroupId && x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = true;
        })
      } else if (x.EventTypeGroupId == data.EventTypeGroupId && !x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = false;
        })
      }
    })
  }
  optionToggled(data) {
    data.AllChecked = data.Types.every(function (x) { return x.status; });
  }

  pauseVid() {
    console.log("video pause");
    this.isDisplay = false;
    this.playVideo = true;

    if (this.stretch && this.channelcountchange == 1) {
      jQuery(".video_player").each((index: number, element: HTMLElement) => {
        const videoElement = jQuery(element)[0];
        videoElement.pause();
      });

    } else if (!this.stretch && this.channelcountchange == 1) {

      jQuery(".videoLargeView").each((index: number, element: HTMLElement) => {
        const videoElement = jQuery(element)[0];
        videoElement.pause();
      });
    } else {
      jQuery(".video_player").each((index: number, element: HTMLElement) => {
        const videoElement = jQuery(element)[0];
        videoElement.pause();
      });
    }

    this.playVideo = true;
    this.seekingfun();
  }
  playVid() {
    console.log("video play");
    this.playVideo = false;
    this.isDisplay = true;

    if (this.stretch && this.channelcountchange == 1) {
      const videoElements = document.querySelectorAll('.video_player');
      videoElements.forEach((video: any) => {
        video.play();
        video.onended = () => {
          video.currentTime = 0;
          video.pause();
        };
      });

    } else if (!this.stretch && this.channelcountchange == 1) {
      const videoElements = document.querySelectorAll('.videoLargeView');
      videoElements.forEach((video: any) => {
        video.play();
        video.onended = () => {
          video.currentTime = 0;
          video.pause();
        };
      });
    } else {
      const videoElements = document.querySelectorAll('.video_player');
      videoElements.forEach((video: any) => {
        video.play();
        video.onended = () => {
          video.currentTime = 0;
          video.pause();
        };
      });
    }
    
    this.seekingfun();
  }


  

  vidEnded() {
    this.playVideo = true;
    this.isDisplay = false;
  };
  closeAll() {
    this.playVideo = true;
    jQuery(".video_player").each(function () {
      this.pause();
      this.currentTime = 0;
    })

  }
  backVideo(val) {
    jQuery(".video_player").each(function () {
      this.currentTime -= val;
    })
    this.seekingfun();
  }
  forVideo(val) {
    jQuery(".video_player").each(function () {
      this.currentTime += val;
    })
    this.seekingfun();
  }

  seekingfun = function () {
    this.seekingfun2("selectedVideo" + this.selectedSingleVideo.ChannelName)
    if (this.channelcountchange === 1) {
      this.seekingfun1("selectedVideo" + this.selectedSingleVideo.ChannelName);
    } else
      this.videoData.forEach((value, key) => {
        if (value.BlobUrl !== null) {
          this.seekingfun1("selectedVideo" + value.ChannelName);
        }
      });
  }


  //seekingfun() {
  //  this.videoData.forEach((value, key) => {
  //    if (value.BlobUrl !== null) {
  //      this.seekingfun1("selectedVideo" + value.ChannelName);
  //    }
  //  });
  //};


  //seekingfun1(id) {
  //  var myVideo: any = document.getElementById(id);
  //  var inputSeek: any = document.getElementById("inputSeek");
  //  myVideo.addEventListener("timeupdate", () => {
  //    var pct = (myVideo.currentTime / myVideo.duration) * 100;
  //    if (myVideo.currentTime !== 0) {
  //      document.getElementById("time").innerHTML = this.timeConvert(myVideo.currentTime);
  //      document.getElementById("duration").innerHTML = this.timeConvert(myVideo.duration);
  //    } else {
  //      document.getElementById("time").innerHTML = '00:00';
  //      document.getElementById("duration").innerHTML = '00:00';
  //    }
  //    inputSeek.value = pct;
  //  });

  //  inputSeek.addEventListener("change", function () {
  //    const videoTime = inputSeek.value * myVideo.duration / 100;
  //    myVideo.currentTime = videoTime;
  //    this.seekingfun();
  //  });
  //};

  //  var inputSeek: any = document.getElementById("inputSeek");
  seekingfun2 = function (id: any) {
    var inputSeek: any = document.getElementById("inputSeek");
    var myVideo: any = document.getElementById(id);
    myVideo.addEventListener("timeupdate", () => {
      var duration = myVideo.duration;
      var pct = (Math.round(myVideo.currentTime) / duration) * 100;
      if (myVideo.currentTime !== 0) {
        document.getElementById("time").innerHTML = this.timeConvert(myVideo.currentTime);
        document.getElementById("duration").innerHTML = this.timeConvert(myVideo.duration);
      } else {
        document.getElementById("time").innerHTML = '00:00';
        document.getElementById("duration").innerHTML = '00:00';
      }
      inputSeek.value = pct;
    });
    myVideo.addEventListener('ended', function () {
      this.onended(() => {
        console.log("it is reading after the video");
        this.playVideo = true;
        jQuery('#inputSeek').val('0');
        document.getElementById("time").innerHTML = '0:00';
      });
    });

  };

  seekingfun1 = function (id: any) {
    var inputSeek: any = document.getElementById("inputSeek");
    var myVideo: any = document.getElementById(id);
    inputSeek.addEventListener("change", function () {
      var videoTime = inputSeek.value * myVideo.duration / 100;
      myVideo.currentTime = videoTime;
      this.seekingfun();
    });

  }


  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + ":" + rminutes;
  }

  isMaximize: boolean = false;
  vidSelect = 0;
  fullScreen(id) {
    //var myVideo: any = document.getElementById("my_video");
    //myVideo.width = 960;

    //jQuery(".video_player").each(function () {
    //  this.requestFullscreen();
    //})
    if (this.vidSelect === 0) {
      jQuery("#" + id).get(0).requestFullscreen();
      this.vidSelect++;
    }
    else {
      jQuery(".video_player").each(function () {
      })
      this.vidSelect = 0;
    }

  }
  videoModal: boolean = true;
  maxmize() {
    this.maxClass = true;
    this.expand = !this.expand
    jQuery(".max").each(function () {
      this.requestFullscreen();
    })
    this.maxVideo = false;
  }
  minimize() {
    this.maxVideo = true;
    this.maxClass = false;
    this.videoModal = false;
    this.videoData = [];
    console.log(this.videosDetails);
    this.playVideo = true;
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmode() {

    if (this.toggleClass == 'ft-minimize') {
      this.toggleClass = 'ft-maximize';
    }
    else {
      this.toggleClass = 'ft-minimize';
    }
    console.log(this.toggleClass)
  }
  //openFullscreen() {
  //  if (this.elem.requestFullscreen) {
  //    this.elem.requestFullscreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.mozRequestFullScreen) {
  //    /* Firefox */
  //    this.elem.mozRequestFullScreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.webkitRequestFullscreen) {
  //    /* Chrome, Safari and Opera */
  //    this.elem.webkitRequestFullscreen();
  //    //this.toggleClass = 'ft-maximize';
  //  } else if (this.elem.msRequestFullscreen) {
  //    /* IE/Edge */
  //    this.elem.msRequestFullscreen();
  //    //this.toggleClass = 'ft-minimize';
  //  }
  //}
  closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }


  openFullscreen4() {
    // Use this.divRef.nativeElement here to request fullscreen
    const elem = this.divRef.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  showpopup: boolean = false;

  muteOptions = function () {
    this.showpopup = true;
  }

  muteOptionsClose = function () {

    this.showpopup = false;
  }

  muteButton = true;
  muteVideo1(chname) {
    this.videoData.forEach((vch, key) => {
      if (vch.BlobUrl !== null) {
        var id = "selectedVideo" + vch.ChannelName;
        var vid = document.getElementById(id) as HTMLMediaElement;

        if (vch.IsMuted && vch.ChannelName == chname) {
          vid.muted = false;
          vid.volume = 1;
          this.muteButton = false;
        }
        else {
          vch.IsMuted = false;
          vid.muted = true;
          this.muteButton = true;
        }
      }
    })
  }

  DownloadVideos(videos) {
    videos.forEach((value, key) => {
      var blob = new Blob([value.BlobUrl], { type: 'video/mp4' });
      var dwldLink = document.createElement("a");
      var url = URL.createObjectURL(blob);
      var isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", value.channelName + '_' + value.Url);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    });
  };

  drop(ev: any, vid) {
    console.log(ev, "drop");
    document.getElementById(vid).setAttribute("src", this.dragingurl);
    console.log(document.getElementById(ev))
    document.getElementById(ev).innerText = this.channelLabel;
  }
  allowDrop(ev: any, vid, url) {
    console.log(ev, vid, url);
    ////document.getElementById(vid).setAttribute("src", this.dragingurl);
    //jQuery("#selectedVideo" + vid).attr("src", this.dragingurl);
    //jQuery("#selectedVideo" + vid).empty();
    ////jQuery("#selectedVideo" + vid).innerHTML = this.channelLabel
    ////console.log(document.getElementById(vid));
    //document.getElementById(url).innerHTML = this.channelLabel;
    //jQuery('#inputSeek').val(0);


    //jQuery('.video_player').each(function () {
    //  var mediaVideo = jQuery("#" + this.id).get(0);
    //  mediaVideo.currentTime = 0;
    //});

    const videoElement = document.getElementById("selectedVideo" + vid) as HTMLVideoElement;
    if (videoElement) {
      videoElement.src = this.dragingurl;
      videoElement.innerHTML = '';
      const urlElement = document.getElementById(url);
      if (urlElement) {
        urlElement.innerHTML = this.channelLabel;
      }
      const inputSeek = document.getElementById('inputSeek') as HTMLInputElement;
      if (inputSeek) {
        inputSeek.value = '0';
      }
      const videoPlayers = document.querySelectorAll('.video_player') as NodeListOf<HTMLVideoElement>;
      videoPlayers.forEach(video => {
        video.currentTime = 0;
      });
    }

    //document.getElementById(vid).setAttribute("src", this.dragingurl);
    //console.log(document.getElementById(ev))
    //document.getElementById(ev).innerText = this.channelLabel;
    // this.videoplayer.nativeElement.src = this.dragingurl;
    //console.log(vid);
    //console.log(jQuery("#" + vid));
    // jQuery("#" + vid).attr("src", this.dragingurl);
  }

  channelLabel = "";
  drag(ev) {
    this.dragingurl = ev.BlobUrl;
    this.channelLabel = ev.ChannelName;
    console.log(ev);
  }

  stretchVids() {
    this.stretch = !this.stretch
  }
  VideoChannelView(cnt) {
    this.isDisplay = false;
    if (cnt === 1) {
      this.displayStretch = true;
      this.selectedSingleVideo = this.videoData.find(x => x.BlobUrl !== null);
      this.stretch = false;
    } else if (cnt === 2) {
      this.displayStretch = false;
      this.stretch = false;
    }
    this.channelcountchange = cnt;
    console.log(cnt);
    jQuery(".video_player").each(function () {
      
      this.currentTime = 0;
    })
    this.pauseVid();
  };

  //MultiVehicles(item) {
  //  if (item !== null) {
  //    const record_ = this.selectedVehicleList.find(x => x.Id === item.Id);
  //    if (record_ === undefined) {
  //      this.selectedVehicleList.push(item);
  //      this.customdate = true;
  //      // console.log("it is radinghere");
  //      if (this.customdate_ !== "")
  //        this.getAllEvents(this.customdate_);
  //      else
  //        this.getAllEvents(this.dateStatus);
  //      //debugger;
  //    }
  //    else {
  //      this.toastrService.error("Selected Vehicle Already Existed!");
        //if (this.customdate_ !== "") {
        //  this.videosDetails = [];
        //}
  //    }
  //  }

  //};
  MultiVehicles(item) {
    if (item !== null) {
      if (this.selectedVehicleList.length !== 0) {
        this.record_ = this.selectedVehicleList.find(x => x.Id === item.Id);
        var record1 = this.selectedVehicleList.forEach((x) => {
          x.DeviceIdenifier === item.DeviceIdentifier
        });
        //var record1 = this.record_.forEach((x) => {
        //  x.DeviceIdenifier === item.DeviceIdentifier
        //});
      }

      if (record1 === undefined) {
        this.selectedVehicleList.push(item);
        this.customdate = true;
        if (this.customdate_ != "") {
          this.getAllEvents(this.customdate_);
        }
        else {
          this.getAllEvents(this.dateStatus);
        }

        // this.getAllEvents(this.dateStatus, this.status);
      }
      else {
        this.toastrService.error("Selected Vehicle Already Existed!");
        if (this.customdate_ !== "") {
          this.videosDetails = [];
        }
      }
    }

  };
  //removeSelectedVrn(deviceid) {
  //  this.selectedVehicleList.forEach((item, i) => {
  //    if (item.DeviceIdentifier === deviceid) {
  //      this.selectedVehicleList.splice(i, 1);
  //      if (this.customdate_ !== "")
  //        this.getAllEvents(this.customdate_);
  //      else
  //        this.getAllEvents(this.dateStatus);
  //     // this.getAllEvents(this.dateStatus);

  //      return;
  //    }
  //  });
  //};
  removeSelectedVrn(deviceid) {
    this.selectedVehicleList.forEach((item, i) => {
      if (item.DeviceIdentifier === deviceid.DeviceIdentifier) {
        this.selectedVehicleList.splice(i, 1);
        if (this.customdate_ != "") {
          this.getAllEvents(this.customdate_);
        }
        else {
          this.getAllEvents(this.dateStatus);
        }

        //this.getAllEvents(this.dateStatus, this.status);
        return;
      }
    });
  };
  AllVehicleschange(status) {
    if (status) {
      this.selectedVehicle = null;
      this.selectedVehicleList = [];
      if (this.customdate_ !== "")
        this.getAllEvents(this.customdate_);
      else
        this.getAllEvents(this.dateStatus);
    }

  };

  //get vehicle based on fleetId
  getVehicles(FleetId) {
    return this.http.get(environment.API_URL + "Devices/" + FleetId + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.vehiclesByFleet = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
}
