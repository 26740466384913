<div class="pre-loader" *ngIf="preloader==true">
  <div class="load-icon">
    <div class="ring-load">
      <div class="circle"></div>
      <!--<img src="assets/images/Favicon.svg" />-->
      <div class="pageload-icon"></div>
    </div>
  </div>
</div>

 
<div class="row m-0">
  <div class="col-lg-4 col-md-12 col-sm-12 col-12 p-0 viewSection">
    <div class="lb-head">
      <div class="clearfix">
        <h6 class="no-m pull-left">Requests</h6>
        <div class="">
          <!--<i class="far fa-filter pull-right" data-toggle="modal" data-target="#filters"></i>-->
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 pr-05">
          <select [(ngModel)]="selectedLevel" (change)="changeDateFun(selectedLevel)" class="form-control">
            <option *ngFor="let item of eventStatusList; index as i" value="{{item.id}}">{{item.name}}</option>
          </select>
        </div>
        <div class="col-6 pl-0">
          <select [(ngModel)]="reqStatus" (change)="changeReqStatus(reqStatus)" class="form-control">
            <option value="all">All</option>
            <ng-container *ngFor="let item of reqStatusDropdown; index as i">
              <option value="{{item.Id}}" *ngIf="item.Id!=2">{{item.Name}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col-6 pr-05" *ngIf="customdate==true">
          <input type="text"
                 placeholder="Date"
                 class="form-control"
                 [(ngModel)]="customdateselect"
                 [maxDate]="maxDate"
                 bsDaterangepicker
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY',isAnimated: true,showWeekNumbers:false}"
                 (bsValueChange)="customDateFun($event)">
        </div>
        <div class="" [ngClass]="{'col-12':customdate==false,'col-6':customdate==true,'pl-0':customdate==true}">
          <input type="text" class="col form-control" placeholder="Search" [(ngModel)]="searchRequests">
        </div>
      </div>
    </div>
    <div class="card-scroll">
      <div *ngIf="RequestList.length==0" class="tab-alert no-records">
          <h5><span style="color:red">No requests</span> recorded for selected date</h5>
      </div>

      <div *ngFor="let item of RequestList;index as i" class="mt-2">
        <div class="fleetNamesSection">
          <a class="row no-m" data-toggle="collapse" href="#coll{{i+1}}" aria-expanded="true">
            <div class="col-12 p-0">
              <h6>{{item.customDate[0]}}</h6>
              <div class="col-xs-1 pull-right">
                <span class="collapsed"><i class="far fa-angle-right"></i></span>
                <span class="expanded"><i class="far fa-angle-down"></i></span>
              </div>
            </div>
          </a>
        </div>
        <div class="collapse show carder" id="coll{{i+1}}">
          <div class="brick" *ngFor="let req of item.RequestList | searchFilter: { RequestType:searchRequests,  FirstName: searchRequests,Status:searchRequests,ReceipientType:searchRequests,Subject:searchRequests};">
            <div class="row no-m brick-header align-items-center" [ngClass]="{'brick-active':selectedid==req.Id}">
              <h6 class="col-7 no-p">
                {{req.VRN}}
                <span [ngClass]="{'highSeverityText':req.EventSeverity==3,'mediumSeverityText':req.EventSeverity==2,'lowSeverityText':req.EventSeverity==1}">: {{req.EventType}}</span>
              </h6>
              <div class="col-4 text-center">
                <span class="pr-3">
                  <span *ngIf="req.ReadStatus==1"><i class="far fa-envelope" tooltip="Unread" placement="top" [hide-delay]="0"></i></span>
                  <span *ngIf="req.ReadStatus==2"><i class="far fa-envelope-open text-success" tooltip="Read" placement="top" [hide-delay]="0"></i></span>
                </span>
                <span class="" *ngIf="req.Status==1||req.Status==2"><i class="far fa-lightbulb" tooltip="New" placement="top" [hide-delay]="0"></i></span>
                <span class="" *ngIf="req.Status==4"><i class="far fa-check-circle text-success" tooltip="Archive" placement="top" [hide-delay]="0"></i></span>
                <span class="" *ngIf="req.Status==3"><i class="far fa-check-circle" tooltip="Completed" placement="top" [hide-delay]="0"></i></span>
              </div>
              <a class="text-right col-1 no-p" (click)="viewRequest(req)">
                <i class="far fa-arrow-alt-circle-right view_icon" tooltip="View" placement="left" [hide-delay]="0"></i>
              </a>
            </div>
            <div class="row no-m brick-body">
              <div class="col-12 no-p">
                <p><i class="far fa-car-crash"></i> {{req.RequestType}}</p>
                <p><i class="far fa-image"></i> {{req.Subject}}</p>
                <p *ngIf="req.DriverName!=null"><i class="fad fa-user-tie"></i> {{req.DriverName}} ({{req.ReceipientType}})</p>
                <p *ngIf="req.ManagerName!=null"><i class="fad fa-user-tie"></i> {{req.ManagerName}} ({{req.ReceipientType}})</p>
                <p>
                  <span tooltip="Date created" placement="right" [hide-delay]="0"><i class="far fa-clock"></i> {{req.CreatedDateString}}</span>
                </p>
                <p *ngIf="req.Status==1||req.Status==2">
                  <span *ngIf="req.DueDateStatus==1" class="text-success" tooltip="Due date" placement="right" [hide-delay]="0">
                    <i class="fad fa-calendar"></i> {{req.DueDateString}}
                  </span>
                  <span *ngIf="req.DueDateStatus==2" class="text-danger" tooltip="Date lapsed" placement="right" [hide-delay]="0">
                    <i class="fad fa-alarm-exclamation"></i> {{req.DueDateString}}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="livefoot">
    <a class="btn btn-charcol pull-right" (click)="createManReq()">Create Request</a>
  </div>-->
  <div class="col-lg-8 col-md-12 col-sm-12 col-12 p-0 shadow" style=" height:100vh;">
    <!--summary section-->
    <div hidden [hidden]="createReqSec||viewReqSection">
      <div class="row no-m">
        <div class="lb-head-single col-12">
          <h6>Requests statistics</h6>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 col-12 no-p" *ngFor="let item of RequestList">
          <div class="panel p-3">
            <div class="fal fa-share-square panelbg panel-green"> </div>
            <div class="panel-info">
              <h4 class="panel-count">{{item.RequestList.length}}</h4>
              <h4 class="panel-title">{{item.customDate[0]}}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--summary section-->
    <!--create request section-->
    <div class="createRequestSection" hidden [hidden]="!createReqSec||viewReqSection">
      <div class="lb-head-single row no-m">
        <a (click)="closeCreateReq()"><i class="far fa-arrow-alt-left view_icon no-m"></i></a>
        <h6 class="pl-2 my-auto">Create Request</h6>
      </div>
      <div class="card-scroll-single p-3">
        <form name="createreqform" (ngSubmit)="onSubmitReqForm()" [formGroup]="createReqForm" class="col-5">
          <div class="form-group">
            <label>Request type</label>
            <select  name="ReqType" class="form-control" formControlName="ReqType" required>
              <option *ngFor="let item of RequestType; index as i" value="{{item.Id}}">{{item.Name}}</option>
            </select>
            <div *ngIf="reqsubmitted && f.ReqType.errors" class="invalid-feedback">
              <div *ngIf="f.ReqType.errors.required">Request type is required</div>
            </div>
          </div>
          <div style="display:flex;">
            <div class="mb-1">
              <input type="radio" name="reqreceipient" value="1"  formControlName="reqreceipient" />
              <label>Manager</label>
            </div>
            <div class="mb-1 ml-1">
              <input type="radio" name="reqreceipient" value="2"  formControlName="reqreceipient" />
              <label>Driver</label>
            </div>
          </div>

          <div class="form-group" *ngIf="reqreceipient!='1'">
            <label>Select Driver</label>
            <select class="form-control" name="ReceipientId"  formControlName="ReceipientId">
              <option *ngFor="let item of driverlist; index as i" value="{{item.Id}}">{{item.Name}}-{{item.PhoneNumber}}-{{item.FleetName}}</option>
            </select>
          </div>
          <div class="form-group" *ngIf="reqreceipient!='2'">
            <label>Select Manager</label>
            <select class="form-control" name="ReceipientId"  formControlName="ReceipientId">
              <option *ngFor="let item of managerlist; index as i" value="{{item.Id}}">{{item.FirstName}} {{item.LastName}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Subject</label>
            <input class="form-control" placeholder="" type="text"  name="ReqSubject" formControlName="ReqSubject" required>
            <div *ngIf="reqsubmitted && f.ReqSubject.errors" class="invalid-feedback">
              <div *ngIf="f.ReqSubject.errors.required">Subject is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Message</label>
            <textarea rows="4" cols="40" class="form-control" placeholder="Type here"  name="ReqMessage" formControlName="ReqMessage"></textarea>
          </div>
          <div class="form-group">
            <button class="btn btn-success float-right mr-3" [disabled]="!createReqForm.valid">Submit</button>
            <img src="../../assets/images/ButtonProcessing.gif" *ngIf="submitManReq" class="float-right" />
          </div>
        </form>
      </div>
    </div>

    <!--view request section-->
    <div class="viewRequestSection" *ngIf="!createReqSec&&viewReqSection">
      <div class="lb-head-single">
        <h6 class="float-left">{{selectedRequest.Subject}}</h6>
        <a class="close" (click)="closeCreateReq()">&times;</a>
      </div>
      <div class="card-scroll p-3">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="fleetNamesSection mb-02">
              <a class="row no-m" data-toggle="collapse" href="#accord" aria-expanded="true">
                <div class="col-12 p-0">
                  <h6>Incident Information</h6>
                  <div class="col-xs-1 pull-right">
                    <span class="collapsed"><i class="far fa-angle-right"></i></span>
                    <span class="expanded"><i class="far fa-angle-down"></i></span>
                  </div>
                </div>
              </a>
            </div>
            <div class="collapse show" id="accord">
              <ul class="striped">
                <li class="row no-m">
                  <span class="col-4">Type</span>
                  <span class="col-8" [ngClass]="{'highSeverityText':selectedRequest.EventSeverity==3,'mediumSeverityText':selectedRequest.EventSeverity==2,'lowSeverityText':selectedRequest.EventSeverity==1}">: {{selectedRequest.EventType}}</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Speed</span>
                  <span class="col-8">: {{selectedRequest.EventSpeed}}</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Date</span>
                  <span class="col-8">: {{selectedRequest.EventDateString}}</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Address</span>
                  <span class="col-8">: {{selectedRequest.EventAddress}}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="fleetNamesSection mb-02">
              <a class="row no-m" data-toggle="collapse" href="#accord" aria-expanded="true">
                <div class="col-12 p-0">
                  <h6>Request Information</h6>
                  <div class="col-xs-1 pull-right">
                    <span class="collapsed"><i class="far fa-angle-right"></i></span>
                    <span class="expanded"><i class="far fa-angle-down"></i></span>
                  </div>
                </div>
              </a>
            </div>
            <div class="collapse show" id="accord">
              <ul class="striped">
                <li class="row no-m">
                  <span class="col-4">Request Type </span>
                  <span class="col-8">: {{selectedRequest.RequestType}}</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Recipient</span>
                  <span class="col-8" *ngIf="selectedRequest.DriverName!=null">: {{selectedRequest.DriverName}} (Driver)</span>
                  <span class="col-8" *ngIf="selectedRequest.ManagerName!=null">: {{selectedRequest.ManagerName}} (Manager)</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Created Date</span>
                  <span class="col-8">: {{selectedRequest.CreatedDateString}}</span>
                </li>
                <li class="row no-m" *ngIf="selectedRequest.Status==1||selectedRequest.Status==2">
                  <span class="col-4">Due Date</span>
                  <span class="col-8" [ngClass]="{'text-danger':selectedRequest.DueDateStatus==2,'text-success':selectedRequest.DueDateStatus==1}">: {{selectedRequest.DueDateString}}</span>
                </li>
                <li class="row no-m">
                  <span class="col-4">Notes</span>
                  <span class="col-8">: {{selectedRequest.Notes}}</span>
                </li>
                <li class="row no-m" *ngIf="selectedRequest.Message!=null">
                  <span class="col-4">Message</span>
                  <span class="col-8">: {{selectedRequest.Message}}</span>
                </li>
                <li class="row no-m" *ngIf="selectedRequest.Status==1||selectedRequest.Status==2">
                  <span class="col-4">Status</span>
                  <span class="col-8">: New</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id='carousel-custom' class="carousel slide" *ngIf="selectedRequest.Status==3||selectedRequest.Status==4">
          <ol class='carousel-top'>
            <li *ngFor="let item of selectedRequest.Media; index as i">

              <div class="thumbnail" *ngIf="selectedRequest.RequestTypeId==1" [ngClass]="{'active':item.blobrequestfileUrl==thumbSelectedReqFileUrl}">
                <span class="check-label" *ngIf="selectedRequest.Status==3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Video{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <video id="" [src]="item.blobrequestfileUrl" class="item-img"></video>
                  <div class="item-info" (click)="ViewIncidentReqFile(selectedRequest,item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequest.RequestTypeId==2" [ngClass]="{'active':item.blobrequestfileUrl==thumbSelectedReqFileUrl}">
                <span class="check-label" *ngIf="selectedRequest.Status==3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Video{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img [src]="item.blobrequestfileUrl" class="item-img">
                  <div class="item-info" (click)="ViewIncidentReqFile(selectedRequest,item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequest.RequestTypeId==3" [ngClass]="{'active':item.blobrequestfileUrl==thumbSelectedReqFileUrl}">
                <span class="check-label" *ngIf="selectedRequest.Status==3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Video{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img src="../../assets/images/doc.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="ViewIncidentReqFile(selectedRequest,item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
              <div class="thumbnail" *ngIf="selectedRequest.RequestTypeId==4" [ngClass]="{'active':item.blobrequestfileUrl==thumbSelectedReqFileUrl}">
                <span class="check-label" *ngIf="selectedRequest.Status==3">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="item.isChecked" (ngModelChange)="AddToEvidenceFun(item)">
                    <span>Video{{i+1}}</span>
                  </label>
                </span>
                <div class="thumbnail-item">
                  <img src="../../assets/images/docs.png" class="item-img contain-fit" />
                  <div class="item-info" (click)="ViewIncidentReqFile(selectedRequest,item)">
                    <i class="fad fa-expand"></i>
                  </div>
                </div>
              </div>
            </li>
          </ol>

          <div class='carousel-inner'>
            <div class='carousel-item active'>
              <div *ngIf="selectedRequest.RequestTypeId==1">
                <video controls id="video" [src]="selectedReqFileUrl" class="thumb-img"></video>
              </div>
              <div *ngIf="selectedRequest.RequestTypeId==2">
                <img [src]="selectedReqFileUrl" class="thumb-img">
              </div>
              <div *ngIf="selectedRequest.RequestTypeId==3">
                <iframe [src]="selectedReqFileUrl" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
              </div>
              <div *ngIf="selectedRequest.RequestTypeId==4">
                <iframe [src]="selectedReqFileUrl" height="1000" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen class="thumb-img"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="livefoot row no-gutters" *ngIf="selectedRequest.Status==3">
        <div class="col">
          <button class="btn btn-warning" (click)="CompleteRequest()">Complete Task</button>
        </div>
        <div class="col-4 d-flex justify-content-end">
          <span class="check_count">Selected <span class="text-success">{{EvidenceFileListCount}}</span> / {{selectedRequestMediaLength}}</span>
          <button class="btn btn-success" (click)="RequestMoveToEvidence()">Move to incident</button>
        </div>
      </div>
    </div>
  </div>
</div>
