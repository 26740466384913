import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http';
import { NgForm, FormsModule, FormBuilder, FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { formatDate, DatePipe, CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
import { environment } from '../../environments/environment';
import { combineAll, debounce } from 'rxjs/operators';
import { Title, DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from '../services/constants.service';
declare var jQuery: any;
import { SearchFilterPipe } from '../customPipes/searchPipe/search-filter.pipe';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewMapComponent } from '../view-map/view-map.component';
import { GoogleMapsModule, GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-incidents',
  standalone: true,
  imports: [CommonModule, NgIf, NgClass, ReactiveFormsModule, FormsModule, GoogleMapsModule, SearchFilterPipe, RouterOutlet
    , BsDatepickerModule, TooltipModule, AutocompleteLibModule, NgMultiSelectDropDownModule, NgxPaginationModule, ViewMapComponent],
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  eventParams: any;
  statusData: any;
  dateData: string;
  driverBehaviors: any = []
  driverBehaviorTotalLenght: any;
  initionLength: any;
  accLength: any;
  alarmVibrationLength: any;
  tabChange: string = "overview";
  dateStatus: any = "1";
  status: any = 3;
  ReqType: any;
  videoEventId: any;
  colp: boolean = true;
  overSpeedLength: any;
  lowBatteryLength: any;
  createManualIncidentForm: FormGroup;
  createReqForm: FormGroup;
  saveAddNotesForm: FormGroup;
  submitted = false;
  error = '';
  zoom: number;
  address: string;
  pipe = new DatePipe('en-US');
  //ComplianceDriverId: string = "";
  //ManagerId: string = "";
  //DriverMateId: string = "";
  //ManagerNotes: string = "";
  //DriverNotes: string = "";
  //DriverMateNotes: string = "";
  //StatusId: number = 1;
  private geoCoder;
  public searchControl: FormControl;
  str: any = 10
  additionalEvidenceForm: FormGroup;
  evidencesubmitted = false;
  gSchockLenght: any;
  eventAuditForm: FormGroup;
  auditsubmitted = false;
  searchRequests: string;
  audituserdata: any = {};
  evidenceFileAccept: string;
  selectedCommand: any;
  selectServercmd: any = [];
  maninctimeerror = false;
  submitManIncLoader = false;
  EvidenceList: any;
  IncidentsList: any;
  FleetListCount: any;
  AssignedEvents: any = [];
  Devicedata: any;
  vehicle: any = {};
  driver: any = {};
  date: string;
  addRes: boolean = true;
  viewManInc: boolean = false;
  summarysection: boolean = true;
  incidensection: boolean = false;
  ananyzeData: any;
  eventlat: number;
  eventlng: number;
  EventVehicle_lat;
  EventVehicle_lng;
  loadingmsg: boolean = false;
  searchEvents: string;
  auditEvents: any;
  heroes: any;
  title: any;
  name: any;
  id: any;
  selected: any;
  selectedLevel: string = "1";
  CustomDateFunctionalityValue: string = "9";
  selectedClassification: string = "0";
  selectedCategory: string = "0";
  selectedReason: string = "0";
  filters: any = {};
  MediaGalleryActiveTab: string;
  filterload: boolean = false;
  customdate: boolean = false;
  fleetFilter: string;
  classificationFilter: string;
  eventtypeFilter: string;
  severityFilter: string;
  defaultAssigned: boolean = false;
  ActivatedFilter: boolean = false;
  reqsubmitted: boolean = false;
  submitManReq: boolean = false;
  createReqSec: boolean = false;
  managerlist: any;
  driverlist: any;
  reqreceipient: string;
  selectedFleets = [];
  incidentServercommands: any = [];
  commandVideoList: any = [];
  MatchedRoadData: boolean = false;
  MapDataStatus: boolean = false;
  dropdownSettings = {};
  maxDate = new Date();
  minDate = new Date();
  dateformat = 'yyyy-MM-dd';
  locale = 'en-US';
  maxClass: boolean = false;
  maxVideo: boolean = true;
  AllVehicles = true;
  selectedVehicleList = [];
  selectedVehicle = null;
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  Classifications: Array<Object> = [
    { Id: 1, Classification: "Incident" },
    { Id: 2, Classification: "Accident" },
    { Id: 3, Classification: "Dismissed" },
    { Id: 4, Classification: "Intervention" }
  ];
  FaultCategories: Array<Object> = [
    { Id: 1, Category: "Device Malfunction" },
    { Id: 2, Category: "Driver Fault" },
    { Id: 3, Category: "False Positive" },
    { Id: 4, Category: "Misc/Other" }
  ];
  RequestsFilterModal: string = "0";
  RequestFilters: Array<Object> = [
    { Id: 1, Name: "New" },
    { Id: 3, Name: "Completed" },
    { Id: 4, Name: "Archive" }
  ];
  FaultCategoryReasons;
  FaultCategory;
  FaultReasons = [];
  incidetData: any = {};
  eventicon;
  analyzeicon;
  analyzeData;
  customdate_;
  selectedImage: any;
  SelectedThumbNailImage;
  imageindex = 0;
  preloader = false;
  vehiclesByFleet;
  fleetload = false;
  displayStretch = false;
  stretch = false
  model: any = {};
  DeviceChannelCount;
  channelcountchange = 2;
  evidencetypes: any;
  evidenceList = [];
  latitude_map: number = 0;
  longitude_map: number = 0;
  submitEvidenceLoader = false;
  audited: boolean = false;
  saveAddNotesLoader: boolean = false;
  selectedIncidentId;
  addnoteslist = [];
  eventAuditList = [];
  public show: boolean = false;
  public icon: any = 'fa-eye';
  createManIncFleetId: string = "";
  vehicleService: any = {};
  Requests: any = [];
  Lattitude;
  Longitude;
  FleetId;
  EventType;
  EventDate;
  DeviceIdentifier;
  event_hours;
  event_min;
  record_ = [];
  record1 = [];
  event_sec;
  severity;
  videostatus = false;
  selectedevidencevideo = [];
  droppedData: string;
  selectedIncidentObj: any;
  polyline = [];
  AnalyzeListPoints = [];
  //gsensor chart
  gtype = 'AreaChart';
  gdata = [];
  gcolumns = ['Year', 'GX', "GY", 'GZ'];
  goptions = {};
  Gsensor_x: boolean = false;
  Gsensor_y: boolean = false;
  Gsensor_z: boolean = false;
  //gsensor chart

  //speed chart
  stype = 'AreaChart';
  sdata = [];
  scolumns = ['Speed', 'Speed'];
  sgoptions = {
    title: ' ',
    hAxis: { title: 'Speed', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 }
  };

  eventStatusList: Array<Object> = [
    { id: 1, name: "Today" },
    { id: 2, name: "Yesterday" },
    { id: 3, name: "Current Week" },
    { id: 4, name: "Last Week" },
    { id: 9, name: "Custom" }
  ];

  @ViewChild('search_', { static: true }) searchElementRef: ElementRef;
  @ViewChild('videoPlayer') evidenceVideoPlayer: ElementRef;
  @ViewChild('evidenceFile_') evidenceFile_: ElementRef;
  @ViewChild('CloseEventAuditModal', { static: true }) CloseEventAuditModal: ElementRef;
  @ViewChild('CloseRequestModal', { static: true }) CloseRequestModal: ElementRef;
  @ViewChild('CloseCompleteRequestModal', { static: true }) CloseCompleteRequestModal: ElementRef;
  @ViewChild('AddAdditionalDetailsModal', { static: true }) AddAdditionalDetailsModal: ElementRef;
  @ViewChild('AddNotesModal', { static: true }) AddNotesModal: ElementRef;
  @ViewChild('IncidentFileterCloseButton', { static: true }) IncidentFileterCloseButton: ElementRef;
  customdatefilter: any;
  AllUsersAssignedEvents: any = [];
  private centerSubject = new BehaviorSubject<google.maps.LatLngLiteral>({ lat: 0, lng: 0 });
  center$ = this.centerSubject.asObservable();
  display: any;
  page = 1;
  totalLength = 0;
  itemsPerPage = 20;
  constructor(private http: HttpClient,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router, private toaster: ToastrService,
    private constants: ConstantsService,) {
    this.titleService.setTitle("Driver Risk-Resolva | Incidents");
  }

  ngOnInit() {

    this.getAuditData();
    //load Places Autocomplete
    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder();
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: ["address"]
    });
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        //set latitude, longitude and zoom
        this.latitude_map = place.geometry.location.lat();
        this.longitude_map = place.geometry.location.lng();
        this.zoom = 12;
        this.Lattitude = this.latitude_map;
        this.Longitude = this.longitude_map;
      });
    });
    this.loadingmsg = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'DeviceIdentifier',
      textField: 'VRN',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.createManualIncidentForm = this.formBuilder.group({
      FleetId: [''],
      EventType: ['', Validators.required],
      EventDate: ['', Validators.required],
      Lattitude: ['', Validators.required],
      DeviceIdentifier: ['', Validators.required],
      Longitude: ['', Validators.required],
      event_hours: ['', Validators.required],
      event_min: ['', Validators.required],
      severity: ['', Validators.required],
      event_sec: ['', Validators.required],
      videostatus: [false]
    });
    this.additionalEvidenceForm = this.formBuilder.group({
      EvidenceRequestId: ['', Validators.required],
      Name: ['', Validators.required],
      ContactNumber: ['', Validators.required],
      EvidenceFile: [''],
      Address: ['', Validators.required]
    });
    this.eventAuditForm = this.formBuilder.group({
      AuditClassification: ['', Validators.required],
      Notes: ['', Validators.required],
      FaultCategory: [''],
      FaultReasons: ['']
    });
    this.saveAddNotesForm = this.formBuilder.group({
      Type: ['', Validators.required],
      Title: ['', Validators.required],
      Description: ['', Validators.required]
    });
    this.createReqForm = this.formBuilder.group({
      ReqType: ['', Validators.required],
      ReqSubject: ['', Validators.required],
      reqreceipient: [''],
      ReceipientId: [''],
      RequestDueDate: ['', Validators.required],
      Request_hours: ['', Validators.required],
      Request_min: ['', Validators.required],
      Request_sec: ['', Validators.required],
      ReqMessage: ['']
    });

    console.log(this.eventAuditForm.valid);
    //getting the incidents data
    //var UserName = localStorage.getItem('UserName');
    //this.getAllEvents(this.dateStatus, this.status);
    this.getFilters();
    this.getDriverBeaviourCount();
  }
  getDriverBeaviourCount() {
    this.http.get(environment.API_URL + "GetFnolEventGroupConfig", { headers: this.reqHeader }).subscribe(data => {
      console.log(data);
    })
  }
  getFilters() {
    const key = "GetEventfilters_" + localStorage.getItem('UserName');
    if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
      this.filters = JSON.parse(localStorage.getItem(key));
      this.changeStatus();
    } else {
      this.http.get(environment.API_URL + 'Device/GetEventFilters/' + localStorage.getItem("FleetId") + "/" + "null", { headers: this.reqHeader }).subscribe((data: any) => {
        this.filters = data;
        this.changeStatus();
      })
    }
    this.getVehicles(localStorage.getItem("FleetId"));
  }

  openInfoWindow(marker: MapMarker) {
    if (this.infoWindow != undefined) this.infoWindow.open(marker);
  }
  toggle() {
    this.show = !this.show;
    if (this.show)
      this.icon = "fa-eye-slash";
    else
      this.icon = "fa-eye";
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude_map = position.coords.latitude;
        this.longitude_map = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }
  //marker drag
  markerDragEnd($event: google.maps.MouseEvent) {
    this.latitude_map = $event.latLng.lat();
    this.longitude_map = $event.latLng.lng();
  }
  //get address
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          this.address = 'No results found';
        }
      } else {
        this.toaster.error('Geocoder failed due to: ' + status);
      }

    });
  }
  //get events list
  dropdownfleetlist: any;
  incidentListlngth: number = 0;
  getAllEvents(date, status) {
    this.statusData = status;
    this.dateData = date;
    this.summarysection = true;
    this.viewManInc = false;
    this.incidensection = false;
    date = date.toString();
    this.loadingmsg = this.preloader = true;
    var DeviceString = "";
    if (this.dateData == "9") {
      this.loadingmsg = this.preloader = false;
      this.toaster.error('please select date range');
      return;
    }
    if (!this.AllVehicles && this.selectedVehicleList.length === 0) {
      this.loadingmsg = this.preloader = false;
      this.toaster.error('please select vehicle');
      this.IncidentsList = []; this.MyListIncidents == 2;
      return;
    }
    if (!this.AllVehicles && this.selectedVehicleList.length !== 0) {
      this.selectedVehicleList.forEach((x: any, y) => {
        DeviceString = DeviceString + x.DeviceIdentifier + ',';
      })
    } else {
      DeviceString = null;
      console.log(status);

    }

    this.http.post(environment.API_URL + "GetFnolEvents/",
      {

        "status": status,
        "date": date,
        "eventfilter": this.eventtypeFilter,
        "classfilter": this.classificationFilter,
        "severityfilter": this.severityFilter,
        "pageindex": this.page,
        "pagesize": this.itemsPerPage,
        "DeviceString": DeviceString

      }, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log("POST Request is successful ", data);
          this.loadingmsg = this.preloader = false;
          this.IncidentsList = data.EventsList;
          this.FleetListCount = data.FleetListCount;
          this.totalLength = data.TotalPageCount;
          //this.selectedVehicle = null;
        },
        error => {

          this.loadingmsg = this.preloader = false;
          console.log("Error", error);
        }
      );
  }

  pageChanged(x) {
    this.page = x;
    this.changeStatus(x);
  };

  //incident list categories
  eventCategory: Array<Object> = [
    { id: 1, name: "Assigned to me" },
    { id: 2, name: "Unassigned" },
    { id: 3, name: "All Events" }
  ];
  MyListIncidents: number = 2;
  mylistCategory(status) {
    this.MyListIncidents = status;
    this.selectedIncidentId = null;
    this.ActivatedFilter = false;
    if (this.dateStatus != this.CustomDateFunctionalityValue) {
      this.getAllEvents(this.dateStatus, 1);
    } else {
      this.getAllEvents(this.customdate_, 1);
    }
  }
  //clear filters
  ClearFilters() {
    this.ActivatedFilter = false;
    this.selectedFleets = [];
    //if (this.dateStatus != this.CustomDateFunctionalityValue) {
    //  this.getAllEvents(this.dateStatus, 1);
    //} else {
    //  this.getAllEvents(this.customdate_, 1);
    //}
    this.getFilters();
  }
  userAssigned() {
    this.defaultAssigned = true;
    this.viewIncident(this.selectedIncidentObj);
    this.defaultAssigned = false;
  }
  userUnAssigned() {
    this.selectedIncidentObj.IsAssigned = false;
    let obj: any = {};
    this.http.post(environment.API_URL + "ExistedUserUnAssigned", {
      "EventId": this.selectedIncidentId,
    }, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Existed user UnAssigned Successfully..!');
          this.defaultAssigned = false;
          this.viewIncident(this.selectedIncidentObj);
        },
        error => {
          this.toaster.error("Error" + error);
        }
      );
  };

  onItemSelect(item: any) {
    console.log(item, 'onitemselect');
    this.MultiVehicles(item);
  }

  onItemDeSelect(item: any) {
    console.log(item, 'onitemdeselect');
    this.removeSelectedVrn(item);
  }

  //get audituser data
  GetEventaudituser(EventId) {
    console.log(this.defaultAssigned);

    this.http.get(environment.API_URL + "GetFnolEventByUserAudit/" + EventId + "/" + localStorage.getItem('UserName') + "/" + this.defaultAssigned, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.audituserdata = data;
          console.log(data, "audituserdata")
          if (this.audituserdata.Accesslimit == true) {
            this.toaster.error("you have already reached your assigned limit." + ' ' + "Please remove auto-assigned.");
          } else {
            if (this.defaultAssigned === true) {
              this.selectedIncidentObj.IsAssigned = true;
            }
          }
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get Driver data
  DriverData(Id) {
    this.http.get(environment.API_URL + "devices/" + Id + "/driver", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.driver = data;
          console.log(data, "DriverData")
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get vehicle data
  VehicleData(Id) {
    this.http.get(environment.API_URL + "GetVehicleRemiders/" + Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.vehicle = data.Vehicle;//serviceReminders
          this.vehicleService = data.serviceReminders;
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //get requests data
  GetRequestList(Filter) {
    this.preloader = true;
    this.http.get(environment.API_URL + "GetIncidentRequestsList/" + this.selectedIncidentObj.EventId + "/" + Filter, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.preloader = false;
          if (data !== null)
            this.Requests = data;//serviceReminders
        },
        error => {
          this.preloader = false;
          console.log("Error", error);
        }
      );
  }
  //get filters data

  //event group type change
  EventGroupTypeChange(data) {
    if (!data.status) {
      data.AllChecked = false;
      data.Types.forEach(x => {
        x.status = false;
      });
    }
  };
  EventTypesCheckAll(data) {
    //data.Types.forEach(x => {
    //  x.status = data.AllChecked;
    //});
    console.log(data);
    this.filters.EventTypesFilter.forEach((x: any, y) => {
      if (x.EventTypeGroupId == data && x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = true;
        })
      } else if (x.EventTypeGroupId == data && !x.AllChecked) {
        x.Types.forEach((p: any, y) => {
          p.FilterChecked = false;
        })
      }
    })
  };
  optionToggled(data) {
    data.AllChecked = data.Types.every(function (x) { return x.status; });
  };

  //custom date functionality
  customDateFun(customdate) {
    this.colp = true;
    if (customdate !== null) {
      this.customdatefilter = customdate;
      console.log(this.customdatefilter);

      var from = customdate[0];
      var to = customdate[1];;
      var date1 = new Date(from);
      var date2 = new Date(to);
      console.log(date1, date2);
      var diffTime = Math.abs(date2.getTime() - date1.getTime());
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays);
      //if (Date.parse(this.pipe.transform(date2, 'yyyy-MM-dd')) >= (Date.parse(this.pipe.transform(date1, 'yyyy-MM-dd')))) {
      //  this.toaster.error("End date should be greater than Start date");
      //  return;
      //}
      if (diffDays <= 7) {
        this.customdate_ = this.pipe.transform(date1, 'yyyy-MM-dd') + "," + this.pipe.transform(date2, 'yyyy-MM-dd');

        this.changeStatus();
      } else {
        this.toaster.error("Date range more than 7 days can't supported, Please select valid date range.");

      }
    }

  };
  //close the infowindow when click on the new marker
  previous;
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  //get selected incident details
  getIncidentDetails(EventId) {
    console.log(EventId);
    this.evidenceFileAccept = "";
    this.preloader = true;
    this.audited = false;
    this.auditfundisable = false;
    //get incident details
    this.http.get(environment.API_URL + "/GetFnolEventById/" + EventId + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //debugger;
          console.log(data);

          if (data.evetId == null) {
            this.toaster.error("Something went wrong with the selected event !");
            return false;
          }
          this.summarysection = false;
          this.incidensection = true;
          this.preloader = false;
          this.incidetData = data;
          console.log(this.incidetData);


          if (this.incidetData.servercommandVideoList !== null && this.incidetData.servercommandVideoList.length !== 0) {
            this.incidentServercommands = this.incidetData.servercommandVideoList;
            this.commandVideoList = this.incidetData.servercommandVideoList[0].Media;
          }
          else {
            this.incidentServercommands = [];
          }

          this.eventlat = data.lat;
          this.eventlng = data.lng;
          this.centerSubject.next({
            lat: this.eventlat,
            lng: this.eventlng
          });
          console.log(this.centerSubject, 'center');
          this.eventicon = {
            url: '../../assets/images/events/' + data.type + data.severity + '.svg',
            scaledSize: {
              height: 40,
              width: 40
            }
          };

          if (this.incidetData.eventReviewStatus != null) {
            this.audited = true;
            this.eventAuditForm.controls['AuditClassification'].setValue(this.incidetData.eventReviewStatus.ClassificationId);
            this.eventAuditForm.controls['FaultCategory'].setValue(this.incidetData.eventReviewStatus.FaultCategoryId);
            this.eventAuditForm.controls['FaultReasons'].setValue(this.incidetData.eventReviewStatus.FaultReasonId);
            this.eventAuditForm.controls['Notes'].setValue(this.incidetData.eventReviewStatus.ReviewNotes);
            this.getFaultReasons();
          }
          if (this.incidetData.imageList.length !== 0) {
            this.selectedImage = this.incidetData.imageList[0].BlobUrl;
          }
          this.getEvidenceList(EventId);
          this.getAddedNotes();
        },
        error => {
          this.preloader = false;
          console.log("Error", error);
        }
      );
  }
  //get evidence list
  getEvidenceList(EventId) {
    this.http.get(environment.API_URL + "/GetEventDataAdditionalData/" + EventId + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //evidence type table
          console.log(data);
          this.evidencetypes = data.RequestType;
          this.evidenceList = data.eventAdditionalData;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //save add notes data
  get n() { return this.saveAddNotesForm.controls; }
  saveAddNotes() {
    this.saveAddNotesLoader = true;
    let obj: any = {};
    obj.UserName = localStorage.getItem('UserName');
    obj.EventId = this.incidetData.evetId;
    obj.NoteType = JSON.parse(this.n.Type.value);
    obj.Title = this.n.Title.value;
    obj.Description = this.n.Description.value;
    this.http.post(environment.API_URL + "Fnol/AddNotes", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Notes added successfully');
          this.saveAddNotesLoader = false;
          this.saveAddNotesForm.reset();
          this.getAddedNotes();
          this.getIncidentDetails(this.incidetData.evetId);
          this.AddNotesModal.nativeElement.click();
        },
        error => {
          this.toaster.error("Error" + error);
          this.saveAddNotesLoader = false;
        }
      );
  };
  //get added notes
  getAddedNotes() {
    this.http.get(environment.API_URL + "Fnol/GetNotes/" + this.incidetData.evetId + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.addnoteslist = data;
        },
        error => {
          console.log("Error" + error);
        }
      );
  };


  // get audit on events page

  getAuditData() {
    this.http.get(environment.API_URL + "Fnol/GetUserNotes/" + "3", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.eventAuditList = data;
        },
        error => {
          console.log("Error" + error);
        })
  }

  //view incident function

  viewEventMap(video: any) {
    this.constants.Viewmap_Str = "Event";
    this.videoEventId = video;
    this.colp = false;
    //this.router.navigate(["view-map"]);
    if (this.defaultAssigned)
      this.GetEventaudituser(this.videoEventId.EventId);
  }
  viewValueMap(value: any) {
    //console.log(value, "viewValueMap EventId")
    this.colp = value
  }


  selectedid;
  viewIncident(data) {

    //this.EvidenceFileListCount = 0;
    //this.RequestsFilterModal = "0";
    //this.IncidentFileterCloseButton.nativeElement.click();
    //this.tripDataObj = {};
    //this.polyline = [];
    //this.selectedid = data.EventId;
    //this.viewManInc = false; 
    //this.incidensection = true;
    //this.summarysection = false;
    //this.selectedIncidentObj = data;
    //this.selectedIncidentId = data.EventId;
    //this.DeviceChannelCount = data.ChannelCount;
    //this.getIncidentDetails(data.EventId);
    //this.GetEventaudituser(data.EventId);
    //this.VehicleData(data.VehicleId);
    //this.DriverData(data.VehicleId);
    //this.GetRequestList(this.RequestsFilterModal);
    //this.getAllManagers(this.selectedIncidentObj.FleetId);
    //this.getAllDrivers(this.selectedIncidentObj.FleetId);
    //this.bounds = {};
    //this.EvidenceFileList = [];
  };
  AllManagers: any = [];
  getAllManagers(FleetId) {
    this.http.get(environment.API_URL + "ManagerNames/" + FleetId, { headers: this.reqHeader }).subscribe((data: any) => {
      this.AllManagers = data;
      console.log(this.AllManagers);
    })
  }
  AllDrivers: any = [];
  getAllDrivers(FleetId) {
    this.http.get(environment.API_URL + "Fleet/GetAllDrivers/" + FleetId, { headers: this.reqHeader }).subscribe((data) => {
      console.log(data);
      this.AllDrivers = data;
    })
  }
  createManInc() {
    this.selectedid = null;
    this.Lattitude = "";
    this.Longitude = "";
    this.FleetId = "";
    this.EventType = "";
    this.EventDate = "";
    this.DeviceIdentifier = "";
    this.event_hours = "";
    this.event_min = "";
    this.event_sec = "";
    this.severity = "";
    this.videostatus = false;
    this.submitted = false;
    this.summarysection = false;
    this.incidensection = false;
    this.viewManInc = true;
    this.maninctimeerror = false;
  };
  //open map window for select location
  openMapWindow() {
    this.searchElementRef.nativeElement.value = "";
    this.setCurrentLocation();
  };
  //event analyze slider
  EventAnalyzeSlider(e) {
    this.EventVehicle_lat = this.ananyzeData[e].SecData[0].stGps.dLat;
    this.EventVehicle_lng = this.ananyzeData[e].SecData[0].stGps.dLon;
  }
  updateDisplayTime(movieplayer) {
    console.log(movieplayer.currentTime);
  }
  closeManInc() {
    this.summarysection = true;
    this.viewManInc = false;
    this.incidensection = false;
  }
  //event analyze
  eventAnalyzeFun() {
    this.selectServercmd = [];
    this.selectedCommand = null;
    return this.http.get(environment.API_URL + "GetAnalyticFile/" + this.incidetData.EventAnalyticsFile + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.analyzeData = JSON.parse(data);
          this.AnalyzeListPoints = this.analyzeData.LocationPoints;
          console.log(this.analyzeData);
          this.analyzeicon = {
            url: '../../assets/images/events/EventAnalyzeIcon2.png',
            scaledSize: {
              height: 15,
              width: 15
            }
          };
          this.AnalyzeBounds = new window['google'].maps.LatLngBounds();
          for (let item of this.analyzeData.LocationPoints) {
            this.AnalyzeBounds.extend(new window['google'].maps.LatLng(item.Latitude, item.Longitude));
          }
          if (this.incidentServercommands.length > 1) {
            this.selectedCommand = this.incidentServercommands[0].CommandData;
            this.changeCommandAnalyze(this.selectedCommand);
          }
          if (this.incidetData.TypeId === 2) {
            this.Gsensor_x = true;
            this.Gsensor_y = false;
            this.Gsensor_z = false;
          } else if (this.incidetData.TypeId === 7) {
            this.Gsensor_x = false;
            this.Gsensor_y = true;
            this.Gsensor_z = false;
          } else {
            this.Gsensor_x = true;
            this.Gsensor_y = true;
            this.Gsensor_z = true;
          }
          this.gsensorgraph(this.Gsensor_x, this.Gsensor_y, this.Gsensor_z);
          this.speedgraph();
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  speedgraph() {
    //speed data
    var tickslen = this.analyzeData.LocationPoints.length;
    var xAxisLabel = -Math.floor(tickslen / 2);
    var ticks = [];
    for (var i = 0; i < tickslen; i++) {
      ticks.push(JSON.stringify(xAxisLabel + i));
    }
    this.sdata = [];
    this.analyzeData.LocationPoints.forEach((val, i) => {
      var temp = [];
      temp.push(ticks[i]);
      temp.push(val.Speed);
      this.sdata.push(temp);
    });
  };
  //g-sensor graph
  gsensorgraph(Gx, Gy, Gz) {
    var Colors;

    if (Gx && Gy && Gz) {
      this.gcolumns = ['Event', 'Gx', 'Gy', 'Gz'];
      Colors = ['orange', 'blue', 'red'];
    } else if (Gx && Gy) {
      this.gcolumns = ['Event', 'Gx', 'Gy'];
      Colors = ['orange', 'blue'];
    } else if (Gx && Gz) {
      this.gcolumns = ['Event', 'Gx', 'Gz'];
      Colors = ['orange', 'red'];
    } else if (Gy && Gz) {
      this.gcolumns = ['Event', 'Gy', 'Gz'];
      Colors = ['blue', 'red'];
    } else if (Gx) {
      this.gcolumns = ['Event', 'Gx'];
      Colors = ['orange'];
    } else if (Gy) {
      this.gcolumns = ['Event', 'Gy'];
      Colors = ['blue'];
    } else if (Gz) {
      this.gcolumns = ['Event', 'Gz'];
      Colors = ['red'];
    }
    //gsensor data

    var tickslen = this.analyzeData.GSensors.length / 100;
    var datalength = Math.round(this.analyzeData.GSensors.length / tickslen);
    var pointlength = 1 / datalength;
    var xAxisLabel = -Math.floor(tickslen / 2);;
    this.gdata = [];
    var ticks = [];
    for (var i = 0; i < tickslen; i++) {
      ticks.push(xAxisLabel + i);
    }
    this.goptions = {
      title: ' ',
      hAxis: {
        title: 'Gsensor',
        titleTextStyle: { color: '#333' },
        viewWindow: {
          min: -4,
          max: 5
        },
        ticks: ticks
      },
      colors: Colors,
      vAxis: { minValue: 0 }
    };
    this.analyzeData.GSensors.forEach((val, i) => {
      var temp = [];
      temp.push(xAxisLabel);
      xAxisLabel = xAxisLabel + pointlength;
      if (Gx) {
        temp.push(val.GSensor.x);
      }
      if (Gy) {
        temp.push(val.GSensor.y);
      }
      if (Gz) {
        temp.push(val.GSensor.z);
      }
      this.gdata.push(temp);
    });
  };
  //change command in event analyze
  changeCommandAnalyze(data) {
    var obj = this.incidetData.servercommandVideoList.find(item => item.CommandData === data);
    this.selectServercmd = obj;
  };
  onMouseOver(infoWindow, $event: google.maps.MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: google.maps.MouseEvent) {
    infoWindow.close();
  }

  prevImageClick() {
    this.imageindex--;
    this.selectedImage = this.incidetData.imageList[this.imageindex].BlobUrl;
  }

  nextImageClick() {
    this.imageindex++;
    this.selectedImage = this.incidetData.imageList[this.imageindex].BlobUrl;
  }


  //get vehicle based on fleetId
  getVehicles(FleetId) {
    this.createManIncFleetId = FleetId;
    return this.http.get(environment.API_URL + "Devices/" + FleetId + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.vehiclesByFleet = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  //create manual incident
  get f() { return this.createManualIncidentForm.controls; }
  onSubmitCreateManualIncident(status) {
    //debugger;
    // stop here if form is invalid
    this.submitted = true;
    if (this.createManualIncidentForm.invalid) {
      return;
    }
    this.submitManIncLoader = true;
    var obj: any = {};
    obj.Lattitude = this.f.Lattitude.value;
    obj.Longitude = this.f.Longitude.value;
    obj.DeviceIdentifier = this.f.DeviceIdentifier.value;
    obj.FleetId = this.createManIncFleetId;
    obj.EventType = JSON.parse(this.f.EventType.value);
    obj.Severity = this.f.severity.value;
    obj.Speed = 0;
    obj.Address = null;
    obj.Status = status;
    obj.EventDate = formatDate(this.f.EventDate.value, 'yyyy-MM-dd', this.locale) + "T" + this.f.event_hours.value + ":" + this.f.event_min.value + ":" + this.f.event_sec.value;
    obj.VideoStatus = this.f.videostatus.value;
    obj.ChannelCount = this.vehiclesByFleet.find(item => item.DeviceIdentifier == obj.DeviceIdentifier).ChannelCount;

    return this.http.post(environment.API_URL + "SaveEventData/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.maninctimeerror = false;
          if (data == false) {
            this.maninctimeerror = true;
          } else {
            this.toaster.success("created successfully");
            //updating the list
            if (this.selectedLevel != this.CustomDateFunctionalityValue) {
              this.getAllEvents(this.dateStatus, 1);
            } else {
              this.getAllEvents(this.customdate_, 1);
            }
            //updating the list
            this.createManualIncidentForm.reset();
            this.closeManInc();
          }
          this.submitManIncLoader = false;
        },
        error => {
          console.log("Error", error);
          this.submitManIncLoader = false;
        }
      );
  }
  dragingurl: string;
  closeManIncWarn() {
    this.maninctimeerror = false;
  }

  stretchVids() {
    this.stretch = !this.stretch
  }
  VideoChannelView(cnt) {
    if (cnt === 1) {
      this.displayStretch = true;
      this.stretch = false;
    } else if (cnt === 2) {
      this.displayStretch = false;
      this.stretch = false;
    }
    this.channelcountchange = cnt;
    console.log(cnt);
    
  };


  //VideoChannelView(cnt) {
  //  this.channelcountchange = cnt;
  //  console.log(cnt);
  //};
  //play evidence video
  playevidencevideo(data) {
    console.log(data);
    this.displayStretch = true
    //if (this.DeviceChannelCount < 9) {
    //  this.channelcountchange = this.DeviceChannelCount;
    //} else {
    //  this.channelcountchange = 9;
    //}
    //this.channelcountchange = this.DeviceChannelCount;
    this.channelcountchange = 1;
    this.selectedevidencevideo = data.Media;
  };
  drop(ev) {
    console.log(ev);
  }
  fullScreen() {
    //var myVideo: any = document.getElementById("my_video");
    //myVideo.width = 960;
    jQuery(".video_player").each(function () {
      this.requestFullscreen();
    })
  }
  allowDrop(ev, Vid) {
    console.log(ev);
    //this.evidenceVideoPlayer.nativeElement.src = this.dragingurl;
    document.getElementById(Vid).setAttribute("src", this.dragingurl);
    console.log(document.getElementById(ev));
    document.getElementById(ev).innerText = this.channelLabel;
  }
  channelLabel = "";
  drag(ev: any) {
    this.dragingurl = ev.BlobUrl;
    this.channelLabel = ev.ChannelName;
    console.log(ev);
  }

  SkipButtons: boolean = false;
  PlayButton: boolean = true;
  video_play() {
    // this.SkipButtons = true;
    this.PlayButton = false;
    // this.evidenceVideoPlayer.nativeElement.play();
    jQuery(".video_player").each(function () {
      this.play();

    });


  };
  vidEnded() {
    this.SkipButtons = false;
    this.PlayButton = true;
  };

  video_pause() {

    this.PlayButton = true;
    // this.evidenceVideoPlayer.nativeElement.pause();
    jQuery(".video_player").each(function () {
      this.pause();
      this.playVideo = true; //this.PlayButton = true;
    })
  };
  videoBackward(value) {
    // this.evidenceVideoPlayer.nativeElement.currentTime -= value;
    jQuery(".video_player").each(function () {
      this.currentTime -= value;
    })
  };
  videoForward(value) {
    //this.evidenceVideoPlayer.nativeElement.currentTime += value;
    jQuery(".video_player").each(function () {
      this.currentTime += value;
    })
  };
  //evidence file restrictions
  evidenceReqType(type) {
    switch (type) {
      case "1":
        this.evidenceFileAccept = ".mp4,.mov,.3gp,.mpg,.flv,.ogg,.mpeg";
        break;
      case "2":
        this.evidenceFileAccept = "image/*";
        break;
      default:
        this.evidenceFileAccept = ".pdf,.doc,.docx";
    }
  };
  //evidence file upload
  fileToUpload: File = null;
  additionalfile: boolean = false;
  handleFileInput(files: FileList) {
    const kb = Math.round(files.item(0).size / 1024);
    const mb = kb / 1024;
    if (mb > 25) {
      this.toaster.error("Please select below 25MB");
      this.EvidenceFile = "";
      return;
    }
    this.additionalfile = true;
    const formdata: FormData = new FormData();
    formdata.append('EvidenceFile', files.item(0), files.item(0).name);
    return this.http.post("/api/upload", formdata)
      .subscribe(
        (data: any) => {
          this.fileToUpload = data.data;
          this.additionalfile = false;
        },
        error => {
          console.log("Error", error);
        }
      );
  }
  //additional evidences
  EvidenceFile: any;
  get e() { return this.additionalEvidenceForm.controls; }
  additionalEvidenceData() {
    this.submitEvidenceLoader = true;
    //additionalEvidenceForm
    var obj: any = {};
    obj.EventId = this.incidetData.evetId;
    obj.EventType = this.incidetData.type;
    obj.EvidenceRequestId = this.e.EvidenceRequestId.value;
    obj.Name = this.e.Name.value;
    obj.ContactNumber = this.e.ContactNumber.value;
    obj.Url = this.fileToUpload;
    obj.Address = this.incidetData.LocationAddress;
    obj.Notes = this.e.Address.value;
    obj.UserName = localStorage.getItem('UserName');
    return this.http.post(environment.API_URL + "SaveEventAdditionalData/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("Evidence Added Successfully...!");
          this.additionalEvidenceForm.reset();
          this.submitEvidenceLoader = false;
          this.getEvidenceList(this.incidetData.evetId);
          this.AddAdditionalDetailsModal.nativeElement.click();
        },
        error => {
          console.log("Error", error);
          this.submitEvidenceLoader = false;
        }
      );
  };
  //event audit functionality
  eventAuditFun() {
    this.auditsubmitted = false;
    this.eventAuditForm.reset();

    //incident audit functionality options
    //return this.http.get(environment.API_URL + "Device/GetEventClassifications/", { headers: this.reqHeader })
    //  .subscribe(
    //    (data: any) => {
    //      this.FaultCategories = data;
    //      console.log(data);
    //    },
    //    error => {
    //      console.log("Error", error);
    //    }
    //  ),
    //  this.http.get(environment.API_URL + "Device/GetFaultCategories/", { headers: this.reqHeader })
    //    .subscribe(
    //      (data: any) => {
    //        this.Classifications = data;
    //        console.log(data);
    //      },
    //      error => {
    //        console.log("Error", error);
    //      }
    //    );
  }
  auditfundisable: boolean = false;
  getAuditClassification() {
    switch (this.a.AuditClassification.value) {
      case "1":
        this.auditfundisable = true;
        break;
      case "2":
        this.auditfundisable = true;
        break;
      default:
        this.auditfundisable = false;
    }
  };
  getFaultReasons() {
    this.http.get(environment.API_URL + "Device/GetFaultCategoryReasons/" + this.a.FaultCategory.value + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.FaultReasons = data;
        },
        error => {
          console.log("Error", error);
        }
      );
  };
  get a() { return this.eventAuditForm.controls; }
  eventAuditSubmit() {
    // stop here if form is invalid
    this.auditsubmitted = true;
    if (this.eventAuditForm.invalid) {
      return;
    }

    var obj: any = {};
    obj.DeviceIdentifier = this.incidetData.DeviceIdentifier;

    if (this.a.AuditClassification.value != "") {
      obj.ClassificationId = JSON.parse(this.a.AuditClassification.value);
    } else {
      obj.ClassificationId = undefined;
    }
    if (this.a.FaultCategory.value != "") {
      obj.CategoryId = JSON.parse(this.a.FaultCategory.value);
    } else {
      obj.CategoryId = undefined;
    }
    if (this.a.FaultReasons.value != "") {
      obj.ReasonId = JSON.parse(this.a.FaultReasons.value);
    } else {
      obj.ReasonId = undefined;
    }

    obj.ReviewNotes = this.a.Notes.value;
    obj.DriverId = this.driver.Id;
    obj.VehicleId = this.vehicle.Id;
    obj.EventId = this.incidetData.evetId;
    obj.FleetId = this.incidetData.FleetId;
    this.http.post(environment.API_URL + "Device/SaveEventAudit/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("Event audited successfully");
          this.eventAuditForm.reset();
          this.getIncidentDetails(this.selectedIncidentId);
          this.CloseEventAuditModal.nativeElement.click();
        },
        error => {
          console.log("Error", error);
        }
      );
  };

  //view trip
  viewTrip() {
    debugger;
    this.preloader = true;
    let obj: any = {};
    //obj.DateTime = this.incidetData.eventDate;
    obj.DateTime = this.pipe.transform(this.incidetData.eventDate, 'yyyy-MM-dd HH:mm:ss');
    obj.deviceIdentifier = this.incidetData.DeviceIdentifier;
    this.http.post(environment.API_URL + "GetTripByEventTime/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          //debugger;
          if (data != null) {
            this.gettripById(data);
          } else {
            this.toaster.error("Something went wrong, trip was unable to find..!");
            this.preloader = false;
          }
        },
        error => {
          this.toaster.error("sorry for inconvenience something went wrong..!");
          this.preloader = false;
        }
      );
  };
  //open media gallery on trip
  OpenGalleryModal(status) {
    this.MediaGalleryActiveTab = status;
  };
  //get trip
  tripDataObj: any = {};
  starticon = {
    url: '../../assets/images/flag-start.png',
    scaledSize: {
      height: 30,
      width: 30
    }
  };
  endicon = {
    url: '../../assets/images/flag-end.png',
    scaledSize: {
      height: 30,
      width: 30
    }
  };
  bounds: any = {};
  AnalyzeBounds: any = {};
  models = [];
  gettripById(id) {
    this.MatchedRoadData = false;
    this.http.get(environment.API_URL + "GettripData/" + this.incidetData.DeviceIdentifier + "/" + id + "/" + localStorage.getItem('UserName') + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.preloader = false;
          this.polyline = data.selectedTrip.routePointslist;
          this.models = data.selectedTrip.routePointslist;
          this.setBounds();
          this.tripDataObj = data.selectedTrip;
          this.MapDataStatus = data.MapDataStatus;
        },
        error => {
          this.toaster.error("sorry for inconvenience something went wrong..!");
          this.preloader = false;
        }
      );
  };
  setBounds() {
    this.bounds = new google.maps.LatLngBounds();

    for (const item of this.models) {
      this.bounds.extend(new google.maps.LatLng(item.lat, item.lng));
    }
  }
  //submit create request form
  get rf() { return this.createReqForm.controls; }
  CreateRequestForm() {
    this.selectedReceipientId = "";
    this.reqreceipient = "1";
    //getting manager names
    //return this.http.get(environment.API_URL + "ManagerNames/" + localStorage.getItem('FleetId'), { headers: this.reqHeader })
    //  .subscribe(
    //    (data: any) => {
    //      this.managerlist = data;
    //    },
    //    error => {
    //      console.log("Error", error);
    //    }
    //  ),//getting Driver names
    //  this.http.get(environment.API_URL + "Devices/DriverList", { headers: this.reqHeader })
    //    .subscribe(
    //      (data: any) => {
    //        console.log(data);
    //        this.driverlist = data;
    //        console.log("driverlist", data)
    //      },
    //      error => {
    //        console.log("Error", error);
    //      }
    //    );
  }
  selectedReceipientId: any;
  selectReceipient(data) {
    this.selectedReceipientId = data.Id;
  };
  onSubmitReqForm() {
    //debugger;
    // stop here if form is invalid
    console.log(this.createReqForm);
    this.reqsubmitted = true;

    if (this.createReqForm.invalid) {
      //this.CloseRequestModal.nativeElement.click();
      this.toaster.error('Fill the Entire Details');
      // this.createReqForm.reset();
      /*alert("Fill the Entire Details and Submit");*/

      return;

    }

    this.submitManReq = true;
    let obj: any = {};
    obj.RequestTypeId = JSON.parse(this.rf.ReqType.value);
    console.log(this.rf);
    obj.ReceipientTypeId = JSON.parse(this.rf.reqreceipient.value);
    obj.ReceipientId = this.selectedReceipientId;
    obj.Subject = this.rf.ReqSubject.value;
    obj.Notes = this.rf.ReqMessage.value;
    obj.IncidentId = this.selectedid;
    obj.RequestedBy = localStorage.getItem('userid');
    obj.ModifiedBy = localStorage.getItem('userid');
    obj.UserName = localStorage.getItem('UserName');
    obj.DueDate = formatDate(this.rf.RequestDueDate.value, 'yyyy-MM-dd', this.locale) + "T" + this.rf.Request_hours.value + ":" + this.rf.Request_min.value + ":" + this.rf.Request_sec.value;
    this.http.post(environment.API_URL + "AddEvidenceRequest/", obj, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success("A new request has been created and assigned..!");
          this.GetRequestList(this.RequestsFilterModal);
          this.CloseRequestModal.nativeElement.click();
          this.createReqForm.reset();
          this.submitManReq = false;
          this.createReqSec = false;
          this.toaster.success("submitted successfully");
        },
        error => {
          this.submitManReq = false;
          this.createReqSec = false;
          console.log("Error", error);
        }
      );
  };
  //view request
  selectedRequest: any;
  selectedRequestMediaLength: any;
  selectedRequestNotes: any;
  selectedRequestReceipient: any;
  selectedRequestMedia: any;
  selectedRequestMessage: any;
  selectedRequestRequestTypeId: any;
  selectedRequestReceipientId: any;
  selectedRequestSubject: any;
  viewRequest(req) {
    //debugger;
    this.EvidenceFileListCount = 0;
    if (req.Media.length != 0) {
      req.Media.forEach(x => {
        x.isChecked = false;
      });
    }
    this.selectedRequest = req;
    this.selectedRequestMedia = req.Media;
    if (req.Media[0].blobrequestfileUrl !== null) {
      this.ActiveSelectedThumNailImage = req.Media[0].blobrequestfileUrl;
      this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(req.Media[0].blobrequestfileUrl);
    }
    this.selectedRequestMediaLength = req.Media.length;
    this.selectedRequestMessage = req.Message;
    this.selectedRequestNotes = req.Notes;
    this.selectedRequestRequestTypeId = req.RequestTypeId;
    if (req.ManagerName != null) {
      this.selectedRequestReceipient = req.ManagerName;
    } else {
      this.selectedRequestReceipient = req.DriverName;
    }
    this.selectedRequestReceipientId = req.ReceipientTypeId;
    //this.selectedRequestSubject = req.Subject;
  };
  viewRequest2(req) {
    //debugger;
    this.EvidenceFileListCount = 0;
    if (req.Media.length != 0) {
      req.Media.forEach(x => {
        x.isChecked = false;
      });
    }
    this.selectedRequest = req;
    this.selectedRequestMedia = req.Media;
    if (req.Media[0].blobrequestfileUrl !== null) {
      this.ActiveSelectedThumNailImage = req.Media[0].blobrequestfileUrl;
      this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(req.Media[0].blobrequestfileUrl);
    }
    this.selectedRequestMediaLength = req.Media.length;
    this.selectedRequestNotes = req.Notes;
    this.selectedRequestRequestTypeId = req.EvidenceRequestId;
    this.selectedRequestReceipient = req.Name;
  };
  //selected thumb nail image
  ActiveSelectedThumNailImage: any;
  SelectedThumbNailImg(item) {
    this.ActiveSelectedThumNailImage = item.blobrequestfileUrl;
    this.SelectedThumbNailImage = this.sanitizer.bypassSecurityTrustResourceUrl(item.blobrequestfileUrl);
  };
  //view request file
  selectedReqFileType: string;
  selectedReqFileUrl: any;
  ViewIncidentReqFile(req, item) {
    if (req.RequestTypeId) {
      this.selectedReqFileType = req.RequestTypeId;
    } else if (req.EvidenceRequestId) {
      this.selectedReqFileType = req.EvidenceRequestId;
    }
    this.selectedReqFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.blobrequestfileUrl);
  };
  //complete task
  CompleteRequest() {
    this.http.get(environment.API_URL + "CompleteRequest/" + this.selectedRequest.Id + "/", { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.CloseCompleteRequestModal.nativeElement.click();
          this.toaster.success('Successfully completed your task.');
          this.EvidenceFileList = [];
          this.GetRequestList(this.RequestsFilterModal);
        },
        error => {
          this.toaster.error('Failed to complete your task.');
        }
      );
  };
  //submit view request
  RequestMoveToEvidence() {
    if (this.EvidenceFileList.length == 0) {
      this.toaster.error("Please select files to move.");
      return false;
    }

    this.http.get(environment.API_URL + "RequestMoveToEvidence/" + this.selectedRequest.Id, { headers: this.reqHeader })
      .subscribe(
        (data: any) => {
          this.toaster.success('Successfully moved to evidence.');
          this.EvidenceFileList = [];
          this.GetRequestList(this.RequestsFilterModal);
          this.getEvidenceList(this.incidetData.evetId);
          this.CloseCompleteRequestModal.nativeElement.click();
        },
        error => {
          this.toaster.error('Failed while moving to evidence.');
          console.log("Error", error);
        }
      );
  };
  //add files to evidence list
  EvidenceFileList: Array<string> = [];
  EvidenceFileListCount: number;
  AddToEvidenceFun(item) {
    if (item.isChecked === true) {
      this.EvidenceFileListCount++;
      this.EvidenceFileList.push(item.filename);
    } else {
      this.EvidenceFileList.splice(this.EvidenceFileList.indexOf(item.filename), 1);
      this.EvidenceFileListCount--;
    }
  };
  eventsCustomDate: any;
  changeStatus(x = null) {
    if (x !== null) {
      this.page = x;
    } else {
      this.page = 1;
    }
    this.colp = true;
    // debugger;
    this.searchEvents = "";
    this.IncidentFileterCloseButton.nativeElement.click();
    localStorage.setItem("GetEventfilters_" + localStorage.getItem('UserName'), JSON.stringify(this.filters));
    //console.log(this.eventsCustomDate, this.customdate_);
    var eventtype = this.filters.EventTypesFilter.filter((item) => { return item.FilterChecked === true });
    var classification = this.filters.clasificationList.filter((item) => { return item.FilterChecked === true });
    var severity = this.filters.SeverityFilter.filter((item) => { return item.FilterChecked === true });
    var fleetString = "", eventString = "", classificationString = "", severityString = "";
    console.log('severity', severity);
    console.log('classification', classification);
    console.log('eventtype', eventtype);
    var Types = [];
    this.filters.clasificationList.forEach(item => {
      if (item.FilterChecked === true && classification != undefined) {
        classificationString += item.Id + ",";
      }
      //else if (classification == undefined) {
      //  classificationString += item.Id + ",";
      //}
    });
    //severity list
    this.filters.SeverityFilter.forEach(item => {
      if (item.FilterChecked === true && severity != undefined) {
        severityString += item.Id + ",";
      } else if (severity == undefined) {
        //severityString += item.Id + ",";
        severityString = "3";
      }
    });
    //event type list
    this.filters.EventTypesFilter.forEach(item => {
      if (item.FilterChecked === true) {
        item.Types.forEach(type => {

          Types.push(type);

        });
      }
    });


    Types.forEach(item => {
      if (item.FilterChecked) {
        eventString += item.Id + ",";
      }
      // else if (eventtype == undefined) {
      //  eventString += item.Id + ",";
      //}
    });
    this.eventtypeFilter = eventString;
    this.classificationFilter = classificationString;
    this.severityFilter = severityString;
    console.log('eventtypeFilter', this.eventtypeFilter);
    console.log('classificationFilter', this.classificationFilter);
    console.log('severityFilter', this.severityFilter);
    if (this.dateStatus != this.CustomDateFunctionalityValue) {
      console.log("it is reading here");
      this.customdate = false;
      this.eventsCustomDate = "";
      this.customdate_ = "";
      if (eventtype == undefined && classification == undefined && severity == undefined && this.dateStatus != this.CustomDateFunctionalityValue) {
        this.ActivatedFilter = false;
        this.fleetFilter = "";
        this.eventtypeFilter = "";
        this.classificationFilter = "";
        this.severityFilter = "";
        this.getAllEvents(this.dateStatus, 1);
        this.filterload = false;
        return;
      }
      this.ActivatedFilter = true;
      //classification list
      //if (classification !== undefined) {
      //  console.log("it is reading here");
      //  classificationString += 'true,'
      //} else {
      //  classificationString += 'false,'
      //}
      this.getAllEvents(this.dateStatus,2);
    } else {
      this.customdate = true;
      console.log("it is readingh here");
      if (this.customdate_ != "")
        this.getAllEvents(this.customdate_, this.status);
    }
  }
  //enable matched road data
  toggleMatchedRoadData(status) {
    console.log(status);
    this.MatchedRoadData = status;
    this.setBounds();
    //this.viewTrip();
  };

  saveData(statusId) {
    console.log(this.incidetData);
    console.log(statusId);
    const Obj: any = {};
    Obj.ManagerId = this.selectedIncidentObj.ManagerId;
    Obj.ComplianceDriverId = this.selectedIncidentObj.ComplianceDriverId;
    Obj.ManagerNotes = this.selectedIncidentObj.ManagerNotes;
    Obj.DriverNotes = this.selectedIncidentObj.DriverNotes;
    Obj.EventId = this.incidetData.evetId;
    Obj.DriverMateId = this.selectedIncidentObj.DriverMateId;
    Obj.DriverMateNotes = this.selectedIncidentObj.DriverMateNotes;
    Obj.StatusId = statusId;
    console.log(Obj);
    if (statusId === 2) {
      if ((Obj.ManagerId === "00000000-0000-0000-0000-000000000000") || (Obj.ComplianceDriverId === "00000000-0000-0000-0000-000000000000") || (Obj.DriverMateId === "00000000-0000-0000-0000-000000000000") || (Obj.DriverNotes === null || Obj.DriverNotes === "") || (Obj.ManagerNotes === null || Obj.ManagerNotes === "")) {
        this.toaster.error("Fill Entire Details and Submit");
        return;
      } if ((Obj.DriverMateId !== "00000000-0000-0000-0000-000000000000")) {
        if (Obj.DriverMateNotes === null || Obj.DriverMateNotes === "") {
          this.toaster.error("Driver Notes Mandatery!");
          return;
        }
      }
    }
    this.http.post(environment.API_URL + "Device/SaveCompliance", Obj, { headers: this.reqHeader }).subscribe((data: any) => {

      this.toaster.success("Compliance updated Successfully..!");
      this.getIncidentDetails(this.incidetData.evetId);


    })
  }
  changeTab(str) {
    console.log(str);
    this.tabChange = str;
  }
  requestonItemChange(str) {
    this.reqreceipient = str;
  }
  maxmize() {
    this.maxClass = true;
    //  this.expand = !this.expand
    console.log("it is not readin here");
    jQuery(".max").each(function () {
      this.requestFullscreen();
    })
    this.maxVideo = false;

  }
  minimize() {
    this.maxVideo = true;
    this.maxClass = false;
    console.log("it is readin here");
    jQuery(".max").each(function () {
      document.exitFullscreen();
    })
  }
  getEventVideo(value) {
    console.log(value, "after submit callVideoEvent Listening");
    this.eventParams = value;
    this.getAllEvents(this.eventParams.date, this.eventParams.status);
    console.log("callVideoEvent Listening");

  }


  MultiVehicles(item) {
    if (item !== null) {
      if (this.selectedVehicleList.length !== 0) {
        this.record_ = this.selectedVehicleList.find(x => x.Id === item.Id);
        var record1 = this.selectedVehicleList.forEach((x) => {
          x.DeviceIdenifier === item.DeviceIdentifier
        });
        //var record1 = this.record_.forEach((x) => {
        //  x.DeviceIdenifier === item.DeviceIdentifier
        //});
      }

      if (record1 === undefined) {
        this.selectedVehicleList.push(item);
        if (this.customdate_ != "") {
          this.getAllEvents(this.customdate_, this.status);
        }
        else {
          this.getAllEvents(this.dateStatus, this.status);
        }

        // this.getAllEvents(this.dateStatus, this.status);
      }
      else {
        this.toaster.error("Selected Vehicle Already Existed!");
      }
    }

  };
  removeSelectedVrn(deviceid) {
    this.selectedVehicleList.forEach((item, i) => {
      if (item.DeviceIdentifier === deviceid.DeviceIdentifier) {
        this.selectedVehicleList.splice(i, 1);
        if (this.customdate_ != "") {
          this.getAllEvents(this.customdate_, this.status);
        }
        else {
          this.getAllEvents(this.dateStatus, this.status);
        }

        //this.getAllEvents(this.dateStatus, this.status);
        return;
      }
    });
  };
  AllVehicleschange(status) {
    if (status) {
      this.selectedVehicle = null;
      this.selectedVehicleList = [];
      if (this.customdate_ != "") {
        this.getAllEvents(this.customdate_, this.status);
      }
      else {
        this.getAllEvents(this.dateStatus, this.status);
      }

    }

  };


}

